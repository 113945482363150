if ($('#pg_charges').length > 0) {

	$(".btn_menu_charges").addClass('active');
	$(".btn_menu_charges").closest('.dropdown').addClass('active');

	// RECEIVE PAYMENT
		$(document).off('click', '.btnReceivePaymentModal').on('click', '.btnReceivePaymentModal', function(e) {
			e.stopPropagation();
			var btn = $(this);
			$('#receivePaymentModal .modal-body').html('');
			ejecutar_ajax('/cuentasxcobrar/' + btn.data('invoice-id') + '/crear', 'html', { _token: btn.data('token') }, btn, function(result) {
				$('#receivePaymentModal .modal-body').html(result);
				$('#date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				$('#receivePaymentModal').modal('show');
			});
			return false;
		});

		$('#receivePaymentModal').on('shown.bs.modal', function (event) {
			$('#amount').focus();
		});

		$('#btnAddCharge').on('click', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/cuentasxcobrar/' + $('#invoice_id').val() + '/almacenar', 'html', $('#frm_receive_payment').serialize(), $(this), function(result) {
				var row = $("#tbl_charges_invoices tbody").find("tr[data-invoice-id='" + $('#invoice_id').val() + "']");
				row.html(result).addClass('warning');
				$('#receivePaymentModal').modal('hide');
				toastr.success('Abono Ingresado');
			});
			return false;
		});

	// SHOW CHARGES
		$(document).off('click', '.btnChargesModal').on('click', '.btnChargesModal', function(e) {
			e.stopPropagation();
			var btn = $(this);
			$('#chargesModal .modal-body').html('');
			ejecutar_ajax('/cuentasxcobrar/' + btn.data('invoice-id'), 'html', { _token: btn.data('token') }, btn, function(result) {
				$('#chargesModal .modal-body').html(result);
				$('#chargesModal').modal('show');
			});
			return false;
		});

	// DELETE CHARGE
		$(document).off('click', '.btnDeleteCharge').on('click', '.btnDeleteCharge', function(e) {
			e.stopPropagation();
			if (confirm('¿Seguro(a) qué desea eliminar el abono seleccionado?')) {
				var btn = $(this);
				var data = {
					_method: 'DELETE',
					_token: btn.data('token')
				}
				ejecutar_ajax('/cuentasxcobrar/' + btn.data('charge-id'), 'html', data, btn, function(result) {
					btn.closest('tr').remove();

					var total = round_dec($('#tbl_charges').data('total'), 2);
					var charges = 0;
					$('#tbl_charges tbody tr').each(function(index) {
						charges += round_dec($(this).data('amount'), 2);
					});
					$('#txt_charges_total').text(round_dec(charges, 2).formatMoney(2, ''));
					var retencion_total = $('#txt_retencion_total').length ? round_dec($('#txt_retencion_total').text(), 2) : 0;
					var isr_total = $('#txt_isr_total').length ? round_dec($('#txt_isr_total').text(), 2) : 0;
					$('#txt_charges_balance h4').text((total - round_dec(charges, 2) - retencion_total - isr_total).formatMoney(2, ''));

					var row = $('#tbl_charges_invoices tbody').find("tr[data-invoice-id='" + $('#txt_invoice_id').val() + "']");
					row.html(result);
					row.addClass('warning');
					toastr.warning('Abono Eliminado');
				});
			}
			return false;
		});

	// SEARCH CHARGES
		$('#customers').chosen({
			no_results_text: 'No se encontraron resultados para',
			allow_single_deselect: true,
			search_contains: true
		});

		$('#customers').on('change', function() {
			var slc = $(this);

			$('#tituloFecha').fadeOut('fast');

			$('#tbl_charges_invoices').fadeOut('fast', function() {

				$('#results').fadeIn('fast', function() {
					var data = {
						_token: slc.data('token'),
						customer_id: slc.val(),
						referrer: globalReferrer,
						option: globalOption,
					}
					ejecutar_ajax('/cuentasxcobrar', 'html', data, false, function(result) {
						$('#results').fadeOut('fast', function() {
							$('#tbl_charges_invoices tbody').html(result);
							$('#tbl_charges_invoices').fadeIn('fast');
						});
					});
				});

			});

		});

	// SET RETENCION
		$(document).off('click', '.btnSetRetencion').on('click', '.btnSetRetencion', function(e) {
			e.stopPropagation();
			var btn = $(this);
			$('#setRetencionModal .modal-body').html('');
			ejecutar_ajax('/cuentasxcobrar/' + btn.data('invoice-id') + '/retencion', 'html', { _token: btn.data('token') }, btn, function(result) {
				$('#setRetencionModal .modal-body').html(result);
				if ($('#retencion_date').length > 0) {
					$('#retencion_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				}
				$('#setRetencionModal').modal('show');
			});
			return false;
		});

		$(document).off('click', '#btnSetRetencion').on('click', '#btnSetRetencion', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/cuentasxcobrar/' + $('#retencion_invoice_id').val() + '/retencion', 'html', $('#frm_set_retencion').serialize(), btn, function(result) {
				var row = $('#tbl_charges_invoices tbody').find("tr[data-invoice-id='" + $('#retencion_invoice_id').val() + "']");
				row.html(result);
				row.addClass('warning');
				toastr.info('Retención Configurada.');
				$('#setRetencionModal').modal('hide');
			});
			return false;
		});

		$(document).off('click', '.btnRemoveRetencion').on('click', '.btnRemoveRetencion', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/cuentasxcobrar/' + btn.data('retencion-id') + '/retencion/remover', 'html', {sid: Math.random()}, btn, function(result) {
				var row = $('#tbl_charges_invoices tbody').find("tr[data-invoice-id='" + btn.data('invoice-id') + "']");
				row.html(result);
				row.addClass('warning');
				toastr.warning('Retención Removida.');
				$('#setRetencionModal').modal('hide');
			});
			return false;
		});

	// SET ISR
		$(document).off('click', '.btnSetIsr').on('click', '.btnSetIsr', function(e) {
			e.stopPropagation();
			var btn = $(this);
			$('#setIsrModal .modal-body').html('');
			ejecutar_ajax('/cuentasxcobrar/' + btn.data('invoice-id') + '/isr', 'html', { _token: btn.data('token') }, btn, function(result) {
				$('#setIsrModal .modal-body').html(result);
				if ($('#isr_date').length > 0) {
					$('#isr_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				}
				$('#setIsrModal').modal('show');
			});
			return false;
		});

		$(document).off('click', '#btnSetIsr').on('click', '#btnSetIsr', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/cuentasxcobrar/' + $('#isr_invoice_id').val() + '/isr', 'html', $('#frm_set_isr').serialize(), btn, function(result) {
				var row = $('#tbl_charges_invoices tbody').find("tr[data-invoice-id='" + $('#isr_invoice_id').val() + "']");
				row.html(result);
				row.addClass('warning');
				toastr.info('ISR Configurado.');
				$('#setIsrModal').modal('hide');
			});
			return false;
		});

		$(document).off('click', '#btnRemoveIsr').on('click', '#btnRemoveIsr', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/cuentasxcobrar/' + $('#isr_invoice_id').val() + '/isr', 'html', $('#frm_remove_isr').serialize(), btn, function(result) {
				var row = $('#tbl_charges_invoices tbody').find("tr[data-invoice-id='" + $('#isr_invoice_id').val() + "']");
				row.html(result);
				row.addClass('warning');
				toastr.warning('ISR Removido.');
				$('#setIsrModal').modal('hide');
			});
			return false;
		});

	// EXPORT CHARGES
		// RETENCION
		$('#retenciones_initial_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
		$('#retenciones_final_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });

		$('#btnChargesRetencionesExport').on('click', function(e) {
			e.stopPropagation();
			if ($('#retenciones_initial_date').val() === '' || $('#retenciones_final_date').val() === '') {
				alert('El campo fecha inicial y/o campo fecha final son obligatorios.');
			} else {
				$('#frmChargesRetencionesExport').submit();
			}
			return false;
		});

		// ISR
		$('#isr_initial_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
		$('#isr_final_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });

		$('#btnChargesIsrExport').on('click', function(e) {
			e.stopPropagation();
			if ($('#isr_initial_date').val() === '' || $('#isr_final_date').val() === '') {
				alert('El campo fecha inicial y/o campo fecha final son obligatorios.');
			} else {
				$('#frmChargesIsrExport').submit();
			}
			return false;
		});
}

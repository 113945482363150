if ($('#pg_offices').length > 0) {

	// SHOW CHECKOUTS
		$(document).off('click', '.btnOfficeCheckoutsModal').on('click', '.btnOfficeCheckoutsModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#checkoutsOfficeModal .modal-body').html('');
			ejecutar_ajax('/cajas/' + button.data('office-id'), 'html', { _token: button.data('token') }, button, function(result) {
				$('#checkoutsOfficeModal .modal-body').html(result);
				$('#checkoutsOfficeModal').modal('show');
			});
			return false;
		});

	// CREATE CHECKOUT
		$(document).off('click', '#btnCreateCheckout').on('click', '#btnCreateCheckout', function(e){
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/cajas/' + btn.data('office-id') + '/almacenar', 'html', { _token: btn.data('token') }, btn, function(result) {
				$('#tbl_checkouts tbody').append(result);
				var row = $('#tbl_offices tbody').find("tr[data-office-id='" + btn.data('office-id') + "']");
				row.children('td').eq(5).children('span').eq(0).text($('#tbl_checkouts tbody tr').length);
				$('#tbl_checkouts tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				toastr.info('Caja Creada');
			});
			return false;
		});

	// DELETE CHECKOUT
		$(document).off('click', '#btnDeleteCheckout').on('click', '#btnDeleteCheckout', function(e){
			e.stopPropagation();
			if (confirm('¿Seguro(a) qué desea reducir el número de cajas?')) {
				var btn = $(this);
				var data = {
					_method: 'DELETE',
					_token: btn.data('token')
				}
				ejecutar_ajax('/cajas/' + btn.data('office-id'), 'html', data, btn, function(result) {
					$('#tbl_checkouts tbody tr:last').remove();
					var row = $('#tbl_offices tbody').find("tr[data-office-id='" + btn.data('office-id') + "']");
					row.children('td').eq(5).children('span').eq(0).text($('#tbl_checkouts tbody tr').length);
					toastr.warning('Caja Eliminada');
				});
			}
			return false;
		});

}

if ($('#pg_computers').length > 0) {

	// ADD COMPUTER
		$('#btn_add_computer').on('click', function(e) {
			e.stopPropagation();
			$('#addComputerModal .modal-body').html('');
			var data = { _token: $(this).data('token') }
			ejecutar_ajax('/dispositivo/crear', 'html', data, $(this), function(result) {
				$('#addComputerModal .modal-body').html(result);
				$('#create_computer_office_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#addComputerModal').modal('show');
			});
			return false;
		});

		$('#addComputerModal').on('shown.bs.modal', function () {
			$('#create_computer_office_id').trigger('chosen:activate');
		});

		$(document).off('change', '#create_computer_office_id').on('change', '#create_computer_office_id', function() {
			$('#create_computer_checkout_id').html('').prop('disabled', true);
			if ($(this).val() != 0) {
				ejecutar_ajax('/dispositivo/' + $(this).val() + '/cajas', 'html', { _token: $(this).data('token') }, null, function(result) {
					$('#create_computer_checkout_id').html(result).prop('disabled', false).focus();
				});
			}
		});

		$('#btn_computers_store').on('click', function(e) {
			e.stopPropagation();
			$('#frm_computers_create').submit();
			return false;
		});

}

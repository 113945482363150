if ($('#pg_suppliers').length > 0) {

	// SHOW CONTACTS
		$(document).off('click', '.btnContactsSupplierModal').on('click', '.btnContactsSupplierModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#contactsSupplierModal .modal-body').html('');
			ejecutar_ajax('/contactos/proveedores/' + button.data('supplier-id'), 'html', { _token: button.data('token') }, button, function(result) {
				$('#contactsSupplierModal .modal-body').html(result);
				$('#contactsSupplierModal').modal('show');
			});
			return false;
		});

		$('#contactsSupplierModal').on('shown.bs.modal', function () {
			$('#supplier_contact_name').focus();
		});

	// ADD CONTACTS
		$(document).off('click', '#btnAddSupplierContact').on('click', '#btnAddSupplierContact', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/contactos/proveedores/' + btn.data('supplier-id') + '/almacenar', 'html', $('#frm_suppliers_contacts').serialize(), btn, function(result) {
				$("#tbl_suppliers_contacts tbody").prepend(result);
				$('#tbl_suppliers_contacts tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				resetInput($('#frm_suppliers_contacts'));
				$('#supplier_contact_name').focus();
			});
			return false;
		});

	// REMOVE CONTACTS
		$(document).off('click', '.btnDeleteSupplierContact').on('click', '.btnDeleteSupplierContact', function(e) {
			e.stopPropagation();
			var btn = $(this);
			if (confirm('¿Seguro(a) qué desea remover el contacto ' + btn.data('contact-name') + '?')) {
				var datos = {
					_token: btn.data('token'),
					_method: 'DELETE'
				}
				ejecutar_ajax('/contactos/proveedores/' + btn.data('contact-id'), 'json', datos, btn, function(result) {
					if (result == 1) {
						$("#tbl_suppliers_contacts tbody").find("tr[data-contact-id='" + btn.data('contact-id') + "']").remove();
					}
				});
			}
			return false;
		});

}

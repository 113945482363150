// SALES
	if ($('#pg_reports_sales').length > 0) {

		$(".btn_menu_reports_sales").addClass('active');
		$(".btn_menu_reports_sales").closest('.dropdown').addClass('active');

		$('#general_initial_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
		$('#general_final_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });

	}

// PURCHASES
	if ($('#pg_reports_purchases').length > 0) {

		$(".btn_menu_reports_purchases").addClass('active');
		$(".btn_menu_reports_purchases").closest('.dropdown').addClass('active');

		$('#general_initial_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
		$('#general_final_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });

	}

// CHARGES
	if ($('#pg_reports_charges').length > 0) {

		$(".btn_menu_reports_charges").addClass('active');
		$(".btn_menu_reports_charges").closest('.dropdown').addClass('active');

		$('#general_initial_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
		$('#general_final_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });

	}

// PAYMENTS
	if ($('#pg_reports_payments').length > 0) {

		$(".btn_menu_reports_payments").addClass('active');
		$(".btn_menu_reports_payments").closest('.dropdown').addClass('active');

		$('#general_initial_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
		$('#general_final_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });

	}

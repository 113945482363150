if ($('#pg_quotes').length > 0) {

	$(".btn_menu_quotes").addClass('active');
	$(".btn_menu_quotes").closest('.dropdown').addClass('active');

	// CREATE QUOTE
		$('#btnCreateQuoteModal').on('click', function(e){
			e.stopPropagation();
			$('#createQuoteModal .modal-body').html('');
			ejecutar_ajax('/cotizaciones/crear', 'html', { _token: $(this).data('token') }, $(this), function(result) {
				$('#createQuoteModal .modal-body').html(result);
				$('#create_customer_id, #create_product_id, #create_service_unit, #create_office_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#create_date, #create_expire').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				sortTable('tbl_services_create', true);
				sortTable('tbl_products_create', true);
				$("#create_exempt").bootstrapSwitch();
				$('#createQuoteModal').modal('show');
			});
			return false;
		});

		function calculateCreateQuoteTotal()
		{
			var total_quote = 0;

			$('#tbl_products_create tbody tr').each(function (index) {
				total_quote += round_dec(Number($(this).data('quantity')) * Number($(this).data('price')), 2);
			});

			$('#tbl_services_create tbody tr').each(function (index) {
				total_quote += round_dec(Number($(this).data('quantity')) * Number($(this).data('price')), 2);
			});

			$('#create_sub_total').val(total_quote.formatMoney(2, ''));

			var discount_percentage = (isNaN($('#create_discount').val()) || $('#create_discount').val() == '' ? 0 : $('#create_discount').val());
			var discount = round_dec(total_quote * (parseFloat(discount_percentage) / 100), 2);
			$('#create_discount_total').val(discount.formatMoney(2, ''));
			total_quote -= discount;

			if ($('#create_exempt').is(':checked')) {
				$('#create_tax').val('0.00');
			} else {
				var tax = round_dec(total_quote * parseFloat($('#create_tax').data('tax')), 2);
				$('#create_tax').val(tax.formatMoney(2, ''));
				total_quote += tax;
			}

			var freight = (isNaN($('#create_freight').val()) || $('#create_freight').val() == '' ? 0 : round_dec($('#create_freight').val(), 2));
			total_quote += freight;

			$('#create_quote_total').text(round_dec(total_quote, 2).formatMoney(2, 'L '));
		}

		$('#createQuoteModal').on('shown.bs.modal', function (event) {
			$('#create_customer_id').trigger('chosen:activate');
		});

		$(document).on('keyup', '#create_discount', function(e){
			if (!isNaN($(this).val())) {
				calculateCreateQuoteTotal();
			}
		});

		$(document).on('keyup', '#create_freight', function(e){
			if (!isNaN($(this).val())) {
				calculateCreateQuoteTotal();
			}
		});

		$(document).on('switchChange.bootstrapSwitch', '#create_exempt', function(event, state) {
			calculateCreateQuoteTotal();
		});

		$(document).on('change', '#create_product_id', function() {
			if ($(this).val() == 0) {
				$('#create_product_price').val('');
			} else {
				$('#create_product_price').val(round_dec($('#create_product_id option:selected').data('price'), 2).formatMoney(2, ''));
				$('#create_product_quantity').focus();
			}
		});

		// ADD SERVICE TO TABLE
			$(document).off('click', '#btn_add_service_create').on('click', '#btn_add_service_create', function(e) {
				e.stopPropagation();

				var btn = $(this);
				var description = $('#create_service_description').val().trim();
				var quantity = (isNaN($('#create_service_quantity').val()) || $('#create_service_quantity').val() == '' ? 0 : Number($('#create_service_quantity').val()));
				var price = (isNaN($('#create_service_price').val()) || $('#create_service_price').val() == '' ? 0 : Number($('#create_service_price').val()));

				var top = $('#create_service_top').val().trim();
				var width = $('#create_service_width').val().trim();
				var depth = $('#create_service_depth').val().trim();
				var unit = $('#create_service_unit').val().trim();
				var location = $('#create_service_location').val().trim();

				if (description != '') {
					if (btn.data('option') == 0) {
						$('#tbl_services_create').find('tbody')
							.append(
								$('<tr>').attr('data-description', description).attr('data-quantity', quantity).attr('data-price', price).attr('data-top', top).attr('data-width', width).attr('data-depth', depth).attr('data-unit', unit).attr('data-location', location)
									.append('<td>&nbsp;</td>')
									.append('<td>' + description + (location != '' ? '<br><small class="text-muted">' + location + '</small>' : '') + '</td>')
									.append('<td>' + top + (width != '' ? ' x ' + width : '') + (depth != '' ? ' x ' + depth : '') + (unit != '' ? ' ' + unit : '') + '</td>')
									.append('<td style="text-align: right;">' + quantity + '</td>')
									.append('<td style="text-align: right;">' + price.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + round_dec(price * quantity, 2).formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-duplicate-service-create"><span class="glyphicon glyphicon-duplicate" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-edit-service-create"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-create"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#tbl_services_create tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
					} else {
						var row = $('#tbl_services_create tbody tr.warning');
						row.data('description', description);
						row.data('quantity', quantity);
						row.data('price', price);
						row.data('top', top);
						row.data('width', width);
						row.data('depth', depth);
						row.data('unit', unit);
						row.data('location', location);
						row.children('td').eq(1).html(description + (location != '' ? '<br><small class="text-muted">' + location + '</small>' : ''));
						row.children('td').eq(2).text(top + (width != '' ? ' x ' + width : '') + (depth != '' ? ' x ' + depth : '') + (unit != '' ? ' ' + unit : ''));
						row.children('td').eq(3).text(quantity);
						row.children('td').eq(4).text(price.formatMoney(2, ''));
						row.children('td').eq(5).text(round_dec(price * quantity, 2).formatMoney(2, ''));
						row.removeClass('warning');
						btn.data('option', 0);
						btn.html('<span class="glyphicon glyphicon-plus" aria-hidden="true"></span>');
					}
					calculateCreateQuoteTotal();
					$('#createQuoteModal').data('bs.modal').handleUpdate();
					$('#create_service_description, #create_service_quantity, #create_service_price, #create_service_top, #create_service_width, #create_service_depth, #create_service_location').val('');
					$('#create_service_unit').val('').trigger('chosen:updated');
					$('#create_service_description').focus();
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// EDIT ELEMENT
			$(document).off('click', '.btn-edit-service-create').on('click', '.btn-edit-service-create', function(e) {
				e.stopPropagation();
				if ($('#btn_add_service_create').data('option') == 1) {
					alert('Ya existe un elemento en estado de edición.');
				} else {
					var row = $(this).closest('tr');
					row.addClass('warning');
					$('#btn_add_service_create').data('option', 1);
					$('#create_service_description').val(row.data('description'));
					$('#create_service_quantity').val(row.data('quantity'));
					$('#create_service_price').val(row.data('price'));
					$('#create_service_top').val(row.data('top'));
					$('#create_service_width').val(row.data('width'));
					$('#create_service_depth').val(row.data('depth'));
					$('#create_service_unit').val(row.data('unit')).trigger('chosen:updated');
					$('#create_service_location').val(row.data('location'));
					$('#btn_add_service_create').html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
					$('#create_service_description').focus();
				}
				return false;
			});

		// DUPLICATE ELEMENT
			$(document).off('click', '.btn-duplicate-service-create').on('click', '.btn-duplicate-service-create', function(e) {
				e.stopPropagation();
				var old = $(this).closest('tr');
				var row = $(this).closest('tr').clone();
				row.removeClass('warning');
				row.data('description', old.data('description'));
				row.data('quantity', old.data('quantity'));
				row.data('price', old.data('price'));
				row.data('top', old.data('top'));
				row.data('width', old.data('width'));
				row.data('depth', old.data('depth'));
				row.data('unit', old.data('unit'));
				row.data('location', old.data('location'));
				row.appendTo('#tbl_services_create tbody');
				$('#tbl_services_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#createQuoteModal').data('bs.modal').handleUpdate();
				calculateCreateQuoteTotal();
				return false;
			});

		// ADD PRODUCT TO TABLE
			$(document).off('click', '#btn_add_product_create').on('click', '#btn_add_product_create', function(e) {
				e.stopPropagation();

				var product_id = ( isNaN( $('#create_product_id').val() ) || $('#create_product_id').val() == '' ? 0 : parseInt( $('#create_product_id').val() ) );
				var quantity = (isNaN($('#create_product_quantity').val()) || $('#create_product_quantity').val() == '' ? 0 : round_dec($('#create_product_quantity').val(), 2));
				var price = (isNaN($('#create_product_id option:selected').data('price')) || $('#create_product_id option:selected').data('price') == '' ? 0 : round_dec($('#create_product_id option:selected').data('price'), 2));

				if (product_id > 0) {
					var check = false;
					$('#tbl_products_create tbody tr').each(function (index) {
						if ($(this).data('product-id') == $('#create_product_id').val()) {
							check = true;
						}
					});
					if (check) {
						alert('El producto ya es parte de la cotización.');
					} else {
						$('#tbl_products_create').find('tbody')
							.append(
								$('<tr>').attr('data-product-id', product_id).attr('data-quantity', quantity).attr('data-price', price)
									.append('<td>&nbsp;</td>')
									.append('<td>' + $('#create_product_id option:selected').text() + '</td>')
									.append('<td style="text-align: right;">' + quantity.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + price.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + round_dec(quantity * price, 2).formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right').attr('style', 'width: 140px;')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-add-quantity-create"><span class="glyphicon glyphicon-chevron-up" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-substract-quantity-create"><span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-create"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#createQuoteModal').data('bs.modal').handleUpdate();
						$('#tbl_products_create tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
						calculateCreateQuoteTotal();
						$('#create_product_id').val(0).trigger('chosen:updated').trigger('chosen:activate');
						$('#create_product_quantity').val('');
						$('#create_product_price').val('');
					}
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// ADD QUANTITY TO ELEMENT
			$(document).off('click', '.btn-add-quantity-create').on('click', '.btn-add-quantity-create', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity++;
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateCreateQuoteTotal();
				return false;
			});

		// SUBSTRACT QUANTITY TO ELEMENT
			$(document).off('click', '.btn-substract-quantity-create').on('click', '.btn-substract-quantity-create', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity--;
				if (quantity <= 0) {
					return false;
				}
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateCreateQuoteTotal();
				return false;
			});

		// REMOVE ELEMENT FROM TABLE
			$(document).off('click', '.btn-remove-element-create').on('click', '.btn-remove-element-create', function(e) {
				e.stopPropagation();
				$(this).closest('tr').remove();
				calculateCreateQuoteTotal();
				$('#tbl_products_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#tbl_services_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#btn_add_service_create').data('option', 0);
				return false;
			});

		// SAVE QUOTE
			$('.btn_quote_create').on('click', function(e) {
				e.stopPropagation();
				var btn = $(this);
				if (btn.data('option') == 1) {
					if (!confirm('¿Seguro(a) qué desea procesar la cotización?')) {
						return false;
					}
				}
				var products = [];
				$('#tbl_products_create tbody tr').each(function (index) {
					products[index] = {
						product_id: $(this).data('product-id'),
						quantity: $(this).data('quantity'),
						price: $(this).data('price')
					}
				});
				var services = [];
				$('#tbl_services_create tbody tr').each(function (index) {
					services[index] = {
						description: $(this).data('description'),
						quantity: $(this).data('quantity'),
						price: $(this).data('price'),

						top: $(this).data('top'),
						width: $(this).data('width'),
						depth: $(this).data('depth'),
						unit: $(this).data('unit'),
						location: $(this).data('location'),
					}
				});
				var data = {
					_token: btn.data('token'),
					customer_id: $('#create_customer_id').val(),
					office_id: $('#create_office_id').val(),
					proyect: $('#create_proyect').val(),
					attention: $('#create_attention').val(),
					address: $('#create_address').val(),
					date: $('#create_date').val(),
					expire: $('#create_expire').val(),
					specifications: $('#create_specifications').val(),
					products: products,
					services: services,
					freight: ((isNaN($('#create_freight').val()) || $('#create_freight').val() == '') ? 0 : round_dec($('#create_freight').val(), 2)),
					discount: ((isNaN($('#create_discount').val()) || $('#create_discount').val() == '') ? 0 : $('#create_discount').val()),
					exempt: $('#create_exempt:checked').size(),
					option: btn.data('option'),
					annotations: $('#create_annotations').val()
				}
				ejecutar_ajax('/cotizaciones/almacenar', 'html', data, btn, function(result) {
					$("#tbl_quotes tbody").prepend(result);
					$('.panel-footer small span').text($('#tbl_quotes tbody tr').length);
					$('#createQuoteModal').modal('hide');
				});
				return false;
			});

	// EDIT QUOTE
		$(document).off('click', '.btnEditQuoteModal').on('click', '.btnEditQuoteModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#editQuoteModal .modal-body').html('');
			ejecutar_ajax('/cotizaciones/' + button.data('quote-id') + '/editar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#editQuoteModal .modal-body').html(result);
				$('#editQuoteModal .modal-title .modal-title-id').text(button.data('number'));
				$('#edit_customer_id, #edit_product_id, #edit_service_unit, #edit_office_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#edit_date, #edit_expire').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				$("#edit_exempt").bootstrapSwitch();
				sortTable('tbl_services_edit', true);
				sortTable('tbl_products_edit', true);
				calculateEditQuoteTotal();
				$('#editQuoteModal').modal('show');
			});
			return false;
		});

		$('#editQuoteModal').on('shown.bs.modal', function (event) {
			$('#edit_customer_id').trigger('chosen:activate');
		});

		function calculateEditQuoteTotal()
		{
			var total_quote = 0;

			$('#tbl_products_edit tbody tr').each(function (index) {
				total_quote += round_dec(Number($(this).data('quantity')) * Number($(this).data('price')), 2);
			});

			$('#tbl_services_edit tbody tr').each(function (index) {
				total_quote += round_dec(Number($(this).data('quantity')) * Number($(this).data('price')), 2);
			});

			$('#edit_sub_total').val(total_quote.formatMoney(2, ''));

			var discount_percentage = (isNaN($('#edit_discount').val()) || $('#edit_discount').val() == '' ? 0 : $('#edit_discount').val());
			var discount = round_dec(total_quote * (parseFloat(discount_percentage) / 100), 2);
			$('#edit_discount_total').val(discount.formatMoney(2, ''));
			total_quote -= discount;

			if ($('#edit_exempt').is(':checked')) {
				$('#edit_tax').val('0.00');
			} else {
				var tax = round_dec(total_quote * parseFloat($('#edit_tax').data('tax')), 2);
				$('#edit_tax').val(tax.formatMoney(2, ''));
				total_quote += tax;
			}

			var freight = (isNaN($('#edit_freight').val()) || $('#edit_freight').val() == '' ? 0 : round_dec($('#edit_freight').val(), 2));
			total_quote += freight;

			$('#edit_quote_total').text(round_dec(total_quote, 2).formatMoney(2, 'L '));
		}

		$(document).on('keyup', '#edit_discount', function(e){
			if (!isNaN($(this).val())) {
				calculateEditQuoteTotal();
			}
		});

		$(document).on('keyup', '#edit_freight', function(e){
			if (!isNaN($(this).val())) {
				calculateEditQuoteTotal();
			}
		});

		$(document).on('switchChange.bootstrapSwitch', '#edit_exempt', function(event, state) {
			calculateEditQuoteTotal();
		});

		$(document).on('change', '#edit_product_id', function() {
			if ($(this).val() == '') {
				$('#edit_product_price').val('');
			} else {
				$('#edit_product_price').val(round_dec($('#edit_product_id option:selected').data('price'), 2).formatMoney(2, ''));
				$('#edit_product_quantity').focus();
			}
		});

		// ADD SERVICE TO TABLE
			$(document).off('click', '#btn_add_service_edit').on('click', '#btn_add_service_edit', function(e) {
				e.stopPropagation();

				var btn = $(this);
				var description = $('#edit_service_description').val().trim();
				var quantity = (isNaN($('#edit_service_quantity').val()) || $('#edit_service_quantity').val() == '' ? 0 : Number($('#edit_service_quantity').val()));
				var price = (isNaN($('#edit_service_price').val()) || $('#edit_service_price').val() == '' ? 0 : Number($('#edit_service_price').val()));

				var top = $('#edit_service_top').val().trim();
				var width = $('#edit_service_width').val().trim();
				var depth = $('#edit_service_depth').val().trim();
				var unit = $('#edit_service_unit').val().trim();
				var location = $('#edit_service_location').val().trim();

				if (description != '') {
					if (btn.data('option') == 0) {
						$('#tbl_services_edit').find('tbody')
							.append(
								$('<tr>').attr('data-description', description).attr('data-quantity', quantity).attr('data-price', price).attr('data-top', top).attr('data-width', width).attr('data-depth', depth).attr('data-unit', unit).attr('data-location', location)
									.append('<td>&nbsp;</td>')
									.append('<td>' + description + (location != '' ? '<br><small class="text-muted">' + location + '</small>' : '') + '</td>')
									.append('<td>' + top + (width != '' ? ' x ' + width : '') + (depth != '' ? ' x ' + depth : '') + (unit != '' ? ' ' + unit : '') + '</td>')
									.append('<td style="text-align: right;">' + quantity + '</td>')
									.append('<td style="text-align: right;">' + price.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + round_dec(price * quantity, 2).formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-duplicate-service-edit"><span class="glyphicon glyphicon-duplicate" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-edit-service-edit"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-edit"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#tbl_services_edit tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
					} else {
						var row = $('#tbl_services_edit tbody tr.warning');
						row.data('description', description);
						row.data('quantity', quantity);
						row.data('price', price);
						row.data('top', top);
						row.data('width', width);
						row.data('depth', depth);
						row.data('unit', unit);
						row.data('location', location);
						row.children('td').eq(1).html(description + (location != '' ? '<br><small class="text-muted">' + location + '</small>' : ''));
						row.children('td').eq(2).text(top + (width != '' ? ' x ' + width : '') + (depth != '' ? ' x ' + depth : '') + (unit != '' ? ' ' + unit : ''));
						row.children('td').eq(3).text(quantity);
						row.children('td').eq(4).text(price.formatMoney(2, ''));
						row.children('td').eq(5).text(round_dec(price * quantity, 2).formatMoney(2, ''));
						row.removeClass('warning');
						btn.html('<span class="glyphicon glyphicon-plus" aria-hidden="true"></span>');
						btn.data('option', 0);
					}
					$('#editQuoteModal').data('bs.modal').handleUpdate();
					calculateEditQuoteTotal();
					$('#edit_service_description, #edit_service_quantity, #edit_service_top, #edit_service_width, #edit_service_depth, #edit_service_location, #edit_service_price').val('');
					$('#edit_service_unit').val('').trigger('chosen:updated');
					$('#edit_service_description').focus();
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// EDIT ELEMENT
			$(document).off('click', '.btn-edit-service-edit').on('click', '.btn-edit-service-edit', function(e) {
				e.stopPropagation();
				if ($('#btn_add_service_edit').data('option') == 1) {
					alert('Ya existe un elemento en estado de edición.');
				} else {
					var row = $(this).closest('tr');
					row.addClass('warning');
					$('#btn_add_service_edit').data('option', 1);
					$('#edit_service_description').val(row.data('description'));
					$('#edit_service_quantity').val(row.data('quantity'));
					$('#edit_service_price').val(row.data('price'));
					$('#edit_service_top').val(row.data('top'));
					$('#edit_service_width').val(row.data('width'));
					$('#edit_service_depth').val(row.data('depth'));
					$('#edit_service_unit').val(row.data('unit')).trigger('chosen:updated');
					$('#edit_service_location').val(row.data('location'));
					$('#btn_add_service_edit').html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
					$('#edit_service_description').focus();
				}
				return false;
			});

		// DUPLICATE ELEMENT
			$(document).off('click', '.btn-duplicate-service-edit').on('click', '.btn-duplicate-service-edit', function(e) {
				e.stopPropagation();
				var old = $(this).closest('tr');
				var row = $(this).closest('tr').clone();
				row.removeClass('warning');
				row.data('description', old.data('description'));
				row.data('quantity', old.data('quantity'));
				row.data('price', old.data('price'));
				row.data('top', old.data('top'));
				row.data('width', old.data('width'));
				row.data('depth', old.data('depth'));
				row.data('unit', old.data('unit'));
				row.data('location', old.data('location'));
				row.appendTo('#tbl_services_edit tbody');
				$('#tbl_services_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#editQuoteModal').data('bs.modal').handleUpdate();
				calculateEditQuoteTotal();
				return false;
			});

		// ADD PRODUCT TO TABLE
			$(document).off('click', '#btn_add_product_edit').on('click', '#btn_add_product_edit', function(e) {
				e.stopPropagation();

				var product_id = ( isNaN( $('#edit_product_id').val() ) || $('#edit_product_id').val() == '' ? 0 : parseInt( $('#edit_product_id').val() ) );
				var quantity = (isNaN($('#edit_product_quantity').val()) || $('#edit_product_quantity').val() == '' ? 0 : round_dec($('#edit_product_quantity').val(), 2));
				var price = (isNaN($('#edit_product_id option:selected').data('price')) || $('#edit_product_id option:selected').data('price') == '' ? 0 : round_dec($('#edit_product_id option:selected').data('price'), 2));

				if (product_id > 0) {
					var check = false;
					$('#tbl_products_edit tbody tr').each(function (index) {
						if ($(this).data('product-id') == $('#edit_product_id').val()) {
							check = true;
						}
					});
					if (check) {
						alert('El producto ya es parte de la cotización.');
					} else {
						$('#tbl_products_edit').find('tbody')
							.append(
								$('<tr>').attr('data-product-id', product_id).attr('data-quantity', quantity).attr('data-price', price)
									.append('<td>&nbsp;</td>')
									.append('<td>' + $('#edit_product_id option:selected').text() + '</td>')
									.append('<td style="text-align: right;">' + quantity.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + price.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + round_dec(quantity * price, 2).formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right').attr('style', 'width: 140px;')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-add-quantity-edit"><span class="glyphicon glyphicon-chevron-up" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-substract-quantity-edit"><span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-edit"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#editQuoteModal').data('bs.modal').handleUpdate();
						$('#tbl_products_edit tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
						calculateEditQuoteTotal();
						$('#edit_product_id').val(0).trigger('chosen:updated').trigger('chosen:activate');
						$('#edit_product_quantity').val('');
						$('#edit_product_price').val('');
					}
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// ADD QUANTITY TO ELEMENT
			$(document).off('click', '.btn-add-quantity-edit').on('click', '.btn-add-quantity-edit', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity++;
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateEditQuoteTotal();
				return false;
			});

		// SUBSTRACT QUANTITY TO ELEMENT
			$(document).off('click', '.btn-substract-quantity-edit').on('click', '.btn-substract-quantity-edit', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity--;
				if (quantity <= 0) {
					return false;
				}
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateEditQuoteTotal();
				return false;
			});

		// REMOVE ELEMENT FROM TABLE
			$(document).off('click', '.btn-remove-element-edit').on('click', '.btn-remove-element-edit', function(e) {
				e.stopPropagation();
				$(this).closest('tr').remove();
				$('#tbl_products_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#tbl_services_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				calculateEditQuoteTotal();
				$('#btn_add_service_edit').data('option', 0);
				return false;
			});

		// UPDATE QUOTE
			$('.btn_quote_edit').on('click', function(e) {
				e.stopPropagation();
				var btn = $(this);
				if (btn.data('option') == 1) {
					if (!confirm('¿Seguro(a) qué desea procesar la cotización?')) {
						return false;
					}
				}
				var products = [];
				$('#tbl_products_edit tbody tr').each(function (index) {
					products[index] = {
						product_id: $(this).data('product-id'),
						quantity: $(this).data('quantity'),
						price: $(this).data('price')
					}
				});
				var services = [];
				$('#tbl_services_edit tbody tr').each(function (index) {
					services[index] = {
						description: $(this).data('description'),
						quantity: $(this).data('quantity'),
						price: $(this).data('price'),
						top: $(this).data('top'),
						width: $(this).data('width'),
						depth: $(this).data('depth'),
						unit: $(this).data('unit'),
						location: $(this).data('location'),
					}
				});
				var data = {
					_token: btn.data('token'),
					_method: 'PUT',
					customer_id: $('#edit_customer_id').val(),
					office_id: $('#edit_office_id').val(),
					proyect: $('#edit_proyect').val(),
					attention: $('#edit_attention').val(),
					address: $('#edit_address').val(),
					date: $('#edit_date').val(),
					expire: $('#edit_expire').val(),
					specifications: $('#edit_specifications').val(),
					products: products,
					services: services,
					freight: ((isNaN($('#edit_freight').val()) || $('#edit_freight').val() == '') ? 0 : round_dec($('#edit_freight').val(), 2)),
					discount: ((isNaN($('#edit_discount').val()) || $('#edit_discount').val() == '') ? 0 : $('#edit_discount').val()),
					exempt: $('#edit_exempt:checked').size(),
					option: btn.data('option'),
					annotations: $('#edit_annotations').val()
				}
				ejecutar_ajax('/cotizaciones/' + $('#edit_quote_id').val(), 'html', data, btn, function(result) {
					var row = $("#tbl_quotes tbody").find("tr[data-quote-id='" + $('#edit_quote_id').val() + "']");
					row.html(result).addClass('warning');
					$('#editQuoteModal').modal('hide');
					toastr.info('Datos Actualizados');
				});
				return false;
			});

	// DELETE QUOTE
		$('#deleteQuoteModal').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			$("#deleteQuoteModal .modal-body span").text(button.data('number'));
			$('#btn_quotes_delete').data('quote-id', button.data('quote-id'));
		});

		$('#btn_quotes_delete').on('click', function(e) {
			e.stopPropagation();
			var quote_id = $(this).data('quote-id');
			var datos = {
				_token: $(this).data('token'),
				_method: 'DELETE'
			}
			ejecutar_ajax('/cotizaciones/' + quote_id, 'json', datos, $(this), function(result) {
				if (result == 1) {
					$("#tbl_quotes tbody").find("tr[data-quote-id='" + quote_id + "']").remove();
					$('.panel-footer small span').text($('#tbl_quotes tbody tr').length);
					$('#deleteQuoteModal').modal('hide');
					toastr.warning('Cotización Eliminada');
				}
			});
			return false;
		});

	// SHOW QUOTE
		$(document).off('click', '.btnShowQuoteModal').on('click', '.btnShowQuoteModal', function(e) {
			e.stopPropagation();
			var button = $(this);
			$('#showQuoteModal .modal-body').html('');
			ejecutar_ajax('/cotizaciones/' + button.data('quote-id'), 'html', { _token: button.data('token') }, button, function(result) {
				$('#showQuoteModal .modal-body').html(result);
				$('#showQuoteModal .modal-title .modal-title-id').text(button.data('number'));
				$('#showQuoteModal').modal('show');
			});
			return false;
		});

	// E-MAIL QUOTE
		$('#emailQuoteModal').on('show.bs.modal', function (event) {
			$('#txt_email_to, txt_email_subject, #txt_email_message').val('');
			if (CKEDITOR.instances.txt_email_message) {
				CKEDITOR.instances.txt_email_message.destroy();
			}
			CKEDITOR.replace('txt_email_message', { language: 'es' });
			CKEDITOR.instances.txt_email_message.setData('');
			var button = $(event.relatedTarget); // Button that triggered the modal
			$('#emailQuoteModal .modal-title-id').text(button.data('number'));
			$('#txt_email_to').val(button.data('email'));
			$('#txt_email_subject').val('Cotización #' + button.data('number'));
			$('#btn_quotes_email').data('quote-id', button.data('quote-id'));
		});

		$('#emailQuoteModal').on('shown.bs.modal', function (event) {
			$('#txt_email_to').focus();
		});

		$(document).off('click', '#btn_quotes_email').on('click', '#btn_quotes_email', function(e) {
			e.stopPropagation();
			var btn = $(this);
			var data = {
				_token: btn.data('token'),
				to: $('#txt_email_to').val(),
				subject: $('#txt_email_subject').val(),
				msg: CKEDITOR.instances['txt_email_message'].getData()
			}
			ejecutar_ajax('/cotizaciones/' + btn.data('quote-id') + '/email', 'json', data, btn, function(result) {
				if (result == 1) {
					$('#emailQuoteModal').modal('hide');
					toastr.info('Cotización Enviada');
				}
			});
			return false;
		});

	// DUPLICATE QUOTE
		$(document).off('click', '#btnDuplicateQuote').on('click', '#btnDuplicateQuote', function(e) {
			e.stopPropagation();
			var button = $(this);
			ejecutar_ajax('/cotizaciones/' + button.data('quote-id') + '/duplicar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#showQuoteModal').modal('hide');
				$('#tbl_quotes tbody').prepend(result);
				$('.panel-footer small span').text($('#tbl_quotes tbody tr').length);
				toastr.info('Cotización Duplicada');
			});
			return false;
		});

	// REJECT QUOTE
		$(document).off('click', '#btnRejectQuote').on('click', '#btnRejectQuote', function(e) {
			e.stopPropagation();
			var button = $(this);
			if (confirm('¿Seguro(a) qué desea rechazar la cotización?')) {
				ejecutar_ajax('/cotizaciones/' + button.data('quote-id') + '/rechazar', 'html', { _token: button.data('token') }, button, function(result) {
					$('#showQuoteModal').modal('hide');
					var row = $("#tbl_quotes tbody").find("tr[data-quote-id='" + button.data('quote-id') + "']");
					row.html(result).addClass('warning');
					toastr.warning('Cotización Rechazada');
				});
			}
			return false;
		});

	// PROCESS QUOTE
		$(document).off('click', '#btnAcceptQuote').on('click', '#btnAcceptQuote', function(e) {
			e.stopPropagation();
			if (confirm('¿Seguro(a) qué desea facturar la cotización?')) {
				var button = $(this);
				ejecutar_ajax('/cotizaciones/' + button.data('quote-id') + '/procesar', 'html', { _token: button.data('token') }, button, function(result) {
					var row = $("#tbl_quotes tbody").find("tr[data-quote-id='" + button.data('quote-id') + "']");
					row.html(result).addClass('warning');
					$('#showQuoteModal').modal('hide');
					toastr.info('Cotización Facturada');
				});
			}
			return false;
		});

	// SEARCH QUOTES
		$('#txt_quote_customer_search, #txt_quote_state_search, #txt_quote_month_search, #txt_quote_year_search').chosen({
			no_results_text: 'No se encontraron resultados para',
			allow_single_deselect: true,
			search_contains: true
		});

		$('.btn_quotes_search').on('click', function(e) {
			e.stopPropagation();
			var btn = $(this);

			if (btn.data('option') == 1 && $('#txt_quote_customer_search').val() == '') {
				$('#txt_quote_customer_search').trigger('chosen:activate');
				return false;
			}

			if (btn.data('option') == 2 && $('#txt_quote_state_search').val() == '') {
				$('#txt_quote_state_search').trigger('chosen:activate');
				return false;
			}

			if (btn.data('option') == 3 && ($('#txt_quote_month_search').val() == '' || $('#txt_quote_year_search').val() == '')) {
				alert('Fecha incompleta.');
				return false;
			}

			var data = {
				_method: 'POST',
				_token: btn.data('token'),
				customer_id: $('#txt_quote_customer_search').val(),
				state: $('#txt_quote_state_search').val(),
				month: $('#txt_quote_month_search').val(),
				year: $('#txt_quote_year_search').val(),
				option: btn.data('option')
			};
			ejecutar_ajax('/cotizaciones/busqueda', 'html', data, btn, function(result) {
				$('#searchModal').modal('hide');
				$("#tbl_quotes tbody").hide().html(result).fadeIn('slow');
				$('.panel-footer small span').text($("#tbl_quotes tbody tr").length);
				var total = $("#tbl_quotes tbody tr").length;
				if (total == 0) {
					toastr.error('No se Encontraron Cotizaciones');
				} else {
					toastr.info(total + ' Cotización(es) Encontrada(s)');
				}
			});

			return false;
		});

}

if ($('#pg_users').length > 0) {

	$(".btn_menu_users").addClass('active');

	// CREATE USER
		$('#btnCreateUserModal').on('click', function(e){
			e.stopPropagation();
			$('#createUserModal .modal-body').html('');
			ejecutar_ajax('/usuarios/crear', 'html', { _token: $(this).data('token') }, $(this), function(result) {
				$('#createUserModal .modal-body').html(result);
				$('#createUserModal').modal('show');
			});
			return false;
		});

		$('#createUserModal').on('shown.bs.modal', function () {
			$('#create_user_name').focus();
		});

		$(document).off('click', '#btn_users_create').on('click', '#btn_users_create', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/usuarios/almacenar', 'html', $('#frm_users_create').serialize(), $(this), function(result) {
				$("#tbl_users tbody").prepend(result);
				$('#tbl_users tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				flash_message('info', 'El usuario fue creado satisfactoriamente.');
				$('#createUserModal').modal('hide');
			});
			return false;
		});

	// EDIT USER
		$(document).off('click', '.btn_users_edit').on('click', '.btn_users_edit', function (e) {
			e.stopPropagation();
			var button = $(this);
			$('#editUserModal .modal-body').html('');
			ejecutar_ajax('/usuarios/' + $(this).data('user-id') + '/editar', 'html', { _token: button.data('token') }, $(this), function (result) {
				$('#editUserModal .modal-body').html(result);
				$("#editUserModal .modal-title .modal-title-name").text(button.data('user-name'));
				$("#edit_user_state").bootstrapSwitch();
				$("#editUserModal").modal('show');

			});
			return false;
		});

		$('#editUserModal').on('shown.bs.modal', function () {
			$('#edit_user_name').focus();
		});

		$(document).off('click', '#btn_users_edit').on('click', '#btn_users_edit', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/usuarios/' + $("#edit_user_id").val(), 'html', $('#frm_users_edit').serialize(), $(this), function(result) {
				$("#tbl_users tbody").find("tr[data-user-id='" + $('#edit_user_id').val() + "']").remove();
				$("#tbl_users tbody").prepend(result);
				$('#tbl_users tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				flash_message('info', 'El usuario fue editado satisfactoriamente.');
				$('#editUserModal').modal('hide');
			});
			return false;
		});

	// DELETE USER
		$('#deleteUserModal').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			$("#deleteUserModal .modal-body span").text(button.data('user-name'));
			$('#btn_users_delete').data('user-id', button.data('user-id'));
		});

		$(document).off('click', '#btn_users_delete').on('click', '#btn_users_delete', function (e) {
			e.stopPropagation();
			var user_id = $(this).data('user-id');
			var datos = {
				_token: $(this).data('token'),
				_method: 'DELETE'
			}
			ejecutar_ajax('/usuarios/' + user_id, 'json', datos, $(this), function(result) {
				if (result == 1) {
					$("#tbl_users tbody").find("tr[data-user-id='" + user_id + "']").remove();
					$('#tbl_users tbody tr').each(function (index) {
						$(this).children('td').eq(0).text(index + 1);
					});
					$('#deleteUserModal').modal('hide');
					flash_message('atencion', 'El usuario fue eliminado satisfactoriamente.');
				} else if (result == 2) {
					alert('Imposible eliminar, el usuario ya posee compras y/o cotizaciones y/o facturas asociadas.');
				}
			});
			return false;
		});

	// USER PERMISSIONS
		$(document).off('click', '.btn_users_permissions').on('click', '.btn_users_permissions', function (e) {
			e.stopPropagation();
			var button = $(this);
			$('#permissionsUserModal .modal-body').html('');
			ejecutar_ajax('/usuarios/' + $(this).data('user-id') + '/permisos', 'html', { _token: button.data('token') }, button, function (result) {
				$('#permissionsUserModal .modal-body').html(result);
				$("#permissionsUserModal .modal-title .modal-title-name").text(button.data('user-name'));
				if ($("#frm_users_permissions").length > 0) {
					$("#frm_users_permissions input:checkbox").bootstrapSwitch();
					$('#btn_users_permissions').prop('disabled', false);
				} else {
					$('#btn_users_permissions').prop('disabled', true);
				}
				$("#permissionsUserModal").modal('show');

			});
			return false;
		});

		$('#btn_users_permissions').on('click', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/usuarios/' + $("#permissions_user_id").val() + '/permisos', 'json', $('#frm_users_permissions').serialize(), $(this), function(result) {
				if (result == 1) {
					$("#tbl_users tbody").find("tr[data-user-id='" + $("#permissions_user_id").val() + "']").addClass('success');
					$('#permissionsUserModal').modal('hide');
					flash_message('info', 'Los permisos fueron editados satisfactoriamente.');
				}
			});
			return false;
		});

}

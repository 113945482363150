if ($('#pg_credit_notes').length > 0) {

	$(".btn_menu_credit_notes").addClass('active');
	$(".btn_menu_credit_notes").closest('.dropdown').addClass('active');

    if ($('.pg_credit_notes_create').length > 0) {
        $('#proveedor').chosen({
            no_results_text: 'No se encontraron resultados para',
            allow_single_deselect: true,
            search_contains: true
        });
        $('#fecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    }

    if ($('.pg_credit_notes_edit').length > 0) {
        $('#proveedor').chosen({
            no_results_text: 'No se encontraron resultados para',
            allow_single_deselect: true,
            search_contains: true
        });
        $('#fecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    }

}

if ($('#pg_payments').length > 0) {

	$(".btn_menu_payments").addClass('active');
	$(".btn_menu_payments").closest('.dropdown').addClass('active');

	// DO PAYMENT
		$(document).off('click', '.btnDoPaymentModal').on('click', '.btnDoPaymentModal', function(e) {
			e.stopPropagation();
			var btn = $(this);
			$('#doPaymentModal .modal-body').html('');
			ejecutar_ajax('/cuentasxpagar/' + btn.data('purchase-id') + '/crear', 'html', { _token: btn.data('token') }, btn, function(result) {
				$('#doPaymentModal .modal-body').html(result);
				$('#date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				$('#doPaymentModal').modal('show');
			});
			return false;
		});

		$('#doPaymentModal').on('shown.bs.modal', function (event) {
			$('#amount').focus();
		});

		$('#btnAddPayment').on('click', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/cuentasxpagar/' + $('#purchase_id').val() + '/almacenar', 'html', $('#frm_do_payment').serialize(), $(this), function(result) {
				var row = $("#tbl_payments_purchases tbody").find("tr[data-purchase-id='" + $('#purchase_id').val() + "']");
				row.html(result).addClass('warning');
				if ($('table.countable').length > 0) {
					var total_payments = 0;
					var total_net = 0;
					$('table.countable tbody tr').each(function(index){
						total_payments += $(this).children('td').eq(6).data('value');
						total_net += $(this).children('td').eq(7).data('value');
					});
					$('#foot_total_payments').html(round_dec(total_payments, 2).formatMoney(2, ''));
					$('#foot_total_net').html(round_dec(total_net, 2).formatMoney(2, ''));
				}
				$('#doPaymentModal').modal('hide');
				toastr.success('Pago Ingresado');
			});
			return false;
		});

	// SHOW PAYMENTS
		$(document).off('click', '.btnPaymentsModal').on('click', '.btnPaymentsModal', function(e) {
			e.stopPropagation();
			var btn = $(this);
			$('#paymentsModal .modal-body').html('');
			ejecutar_ajax('/cuentasxpagar/' + btn.data('purchase-id'), 'html', { _token: btn.data('token') }, btn, function(result) {
				$('#paymentsModal .modal-body').html(result);
				$('#paymentsModal').modal('show');
			});
			return false;
		});

	// DELETE PAYMENT
		$(document).off('click', '.btnDeletePayment').on('click', '.btnDeletePayment', function(e) {
			e.stopPropagation();
			if (confirm('¿Seguro(a) qué desea eliminar el pago seleccionado?')) {
				var btn = $(this);
				var data = {
					_method: 'DELETE',
					_token: btn.data('token')
				}
				ejecutar_ajax('/cuentasxpagar/' + btn.data('payment-id'), 'html', data, btn, function(result) {
					btn.closest('tr').remove();

					var total = round_dec($('#tbl_payments').data('total'), 2);
					var payments = 0;
					$('#tbl_payments tbody tr').each(function(index) {
						payments += round_dec($(this).data('amount'), 2);
					});
					$('#txt_payments_total').text(round_dec(payments, 2).formatMoney(2, ''));
					$('#txt_payments_balance h4').text(round_dec(total - round_dec(payments, 2), 2).formatMoney(2, ''));

					var row = $('#tbl_payments_purchases tbody').find("tr[data-purchase-id='" + $('#txt_purchase_id').val() + "']");
					row.html(result);
					row.addClass('warning');
					if ($('table.countable').length > 0) {
						var total_payments = 0;
						var total_net = 0;
						$('table.countable tbody tr').each(function(index){
							total_payments += $(this).children('td').eq(6).data('value');
							total_net += $(this).children('td').eq(7).data('value');
						});
						$('#foot_total_payments').html(round_dec(total_payments, 2).formatMoney(2, ''));
						$('#foot_total_net').html(round_dec(total_net, 2).formatMoney(2, ''));
					}
					toastr.warning('Pago Eliminado');
				});
			}
			return false;
		});

	// SEARCH PAYMENTS
		$('#supplier').chosen({
			no_results_text: 'No se encontraron resultados para',
			allow_single_deselect: true,
			search_contains: true
		});

		/*$('#suppliers').on('change', function() {
			var slc = $(this);

			$('#tbl_payments_purchases').fadeOut('fast', function() {

				$('#results').fadeIn('fast', function() {
					var data = {
						_token: slc.data('token'),
						supplier_id: slc.val()
					}
					ejecutar_ajax('/cuentasxpagar', 'html', data, false, function(result) {
						$('#results').fadeOut('fast', function() {
							$('#tbl_payments_purchases tbody').html(result);
							$('#tbl_payments_purchases').fadeIn('fast');
						});
					});
				});

			});

		});*/

}

if ($('#pg_account').length > 0) {

	$(".btn_menu_account").addClass('active');
	$(".btn_menu_account").closest('.dropdown').addClass('active');

	// CONFIGURATION
	$('#btn_config_save').on('click', function(e) {
		e.stopPropagation();
		ejecutar_ajax('/configuracion', 'json', $('#frm_config').serialize(), $(this), function(result) {
			if (result == 1) {
				$('#configModal').modal('hide');
				flash_message('info', 'Configuración guardada satisfactoriamente.');
			}
		});
		return false;
	});

	if ($("#mainChartDiv").length > 0) {
		var ctx = $("#mainChart").get(0).getContext("2d");
		var mainChart = new Chart(ctx).Bar(chartData);
	}

	if ($("#incomesOutcomesChartDiv").length > 0) {
		var ctx = $("#incomesOutcomesChart").get(0).getContext("2d");
		var incomesOutcomesChart = new Chart(ctx).Bar(incomesOutcomesData);
	}

}

if ($('#pg_purchases').length > 0) {

	$(".btn_menu_purchases").addClass('active');
	$(".btn_menu_purchases").closest('.dropdown').addClass('active');

	// CREATE PURCHASE
		$('#btnCreatePurchaseModal').on('click', function(e){
			e.stopPropagation();
			$('#createPurchaseModal .modal-body').html('');
			ejecutar_ajax('/compras/crear', 'html', { _token: $(this).data('token') }, $(this), function(result) {
				$('#createPurchaseModal .modal-body').html(result);
				$('#create_supplier_id, #create_product_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#create_term, #create_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				sortTable('tbl_services_create', true);
				sortTable('tbl_products_create', true);
				$("#create_exempt").bootstrapSwitch();
				$('#createPurchaseModal').modal('show');
			});
			return false;
		});

		$('#createPurchaseModal').on('shown.bs.modal', function (event) {
			$('#create_supplier_id').trigger('chosen:activate');
		});

		$(document).on('change', '#create_type', function() {
			if ($(this).val() == 'Crédito') {
				$('#create_term').prop('disabled', false).focus();
			}

			if ($(this).val() == 'Contado') {
				$('#create_term').prop('disabled', true);
			}
		});

		$(document).on('change', '#create_supplier_id', function() {
			var supplier_id = $(this).val();
			var token = $(this).data('token');
			if (supplier_id) {
				$('#create_cai').html('<option value="">Cargando...</option>');
				ejecutar_ajax('/compras/cais', 'html', { _token: token, supplier_id: supplier_id }, null, function(result) {
					$('#create_cai').html(result);
				});
			} else {
				$('#create_cai').html('<option value="">-- Seleccione --</option>');
			}
		});

		function calculateCreatePurchaseTotal()
		{
			var total = 0;
			var total_products = 0;
			var total_services = 0;

			$('#tbl_services_create tbody tr').each(function (index) {
				total_services += round_dec($(this).data('amount'), 2);
			});

			$('#tbl_products_create tbody tr').each(function (index) {
				total_products += round_dec($(this).data('amount'), 2);
			});

			total = round_dec(total_products + total_services, 2);
			$('#create_sub_total').val(total.formatMoney(2, ''));

			var discount = ((isNaN($('#create_discount').val()) || $('#create_discount').val() == '') ? 0 : $('#create_discount').val());
			total -= round_dec(discount, 2);

			if ($('#create_exempt').is(':checked')) {
				$('#create_tax').val('0.00');
			} else {
				var tax = total * round_dec($('#create_tax').data('tax'), 2);
				$('#create_tax').val(tax.formatMoney(2, ''));
				total += round_dec(tax, 2);
			}

			var freight = ((isNaN($('#create_freight').val()) || $('#create_freight').val() == '') ? 0 : $('#create_freight').val());
			total += round_dec(freight, 2);

			$('#create_purchase_total').text(round_dec(total, 2).formatMoney(2, 'L '));
		}

		$(document).on('keyup', '#create_discount', function(e){
			if (!isNaN($(this).val())) {
				calculateCreatePurchaseTotal();
			}
		});

		$(document).on('keyup', '#create_freight', function(e){
			if (!isNaN($(this).val())) {
				calculateCreatePurchaseTotal();
			}
		});

		$(document).on('switchChange.bootstrapSwitch', '#create_exempt', function(event, state) {
			calculateCreatePurchaseTotal();
		});

		// SEARCH FOR EXISTING INVOICE NUMBER
		$(document).off('blur', '#create_invoice').on('blur', '#create_invoice', function() {
			var invoice = $(this);
			var supplier = $('#create_supplier_id');
			if (invoice && supplier) {
				var data = {
					type: 'create',
					invoice: invoice.val(),
					supplier: supplier.val(),
					_token: invoice.data('token')
				};
				ejecutar_ajax('/compras/factura_existente', 'json', data, null, function(result) {
					if (result.status == 1) {
						alert('El número de factura introducido ya existe en el sistema para el proveedor seleccionado.');
					}
				});
			}
		});

		// ADD SERVICE TO TABLE
			$(document).off('click', '#btn_add_service_create').on('click', '#btn_add_service_create', function(e) {
				e.stopPropagation();

				var btn = $(this);
				var description = $('#create_service_description').val().trim();
				var quantity = (isNaN($('#create_service_quantity').val()) || $('#create_service_quantity').val() == '' ? 0 : $('#create_service_quantity').val());
				var amount = (isNaN($('#create_service_amount').val()) || $('#create_service_amount').val() == '' ? 0 : round_dec($('#create_service_amount').val(), 2));

				if (description != '' && amount > 0 && quantity > 0) {
					if (btn.data('option') == 0) {
						$('#tbl_services_create').find('tbody')
							.append(
								$('<tr>').attr('data-description', description).attr('data-quantity', quantity).attr('data-amount', amount)
									.append('<td>&nbsp;</td>')
									.append('<td>' + description + '</td>')
									.append('<td style="text-align: right;">' + quantity + '</td>')
									.append('<td style="text-align: right;">' + round_dec(amount / quantity, 2).formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + amount.formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-duplicate-service-create"><span class="glyphicon glyphicon-duplicate" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-edit-service-create"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-create"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#tbl_services_create tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
					} else {
						var row = $('#tbl_services_create tbody tr.warning');
						row.data('description', description);
						row.data('quantity', quantity);
						row.data('amount', amount);
						row.children('td').eq(1).html(description);
						row.children('td').eq(2).text(quantity);
						row.children('td').eq(3).text(round_dec(amount / quantity, 2).formatMoney(2, ''));
						row.children('td').eq(4).text(amount.formatMoney(2, ''));
						row.removeClass('warning');
						btn.data('option', 0);
						btn.html('<span class="glyphicon glyphicon-plus" aria-hidden="true"></span>');
					}
					calculateCreatePurchaseTotal();
					$('#createPurchaseModal').data('bs.modal').handleUpdate();
					$('#create_service_description, #create_service_quantity, #create_service_amount').val('');
					$('#create_service_description').focus();
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// EDIT ELEMENT
			$(document).off('click', '.btn-edit-service-create').on('click', '.btn-edit-service-create', function(e) {
				e.stopPropagation();
				if ($('#btn_add_service_create').data('option') == 1) {
					alert('Ya existe un elemento en estado de edición.');
				} else {
					var row = $(this).closest('tr');
					row.addClass('warning');
					$('#btn_add_service_create').data('option', 1);
					$('#create_service_description').val(row.data('description'));
					$('#create_service_quantity').val(row.data('quantity'));
					$('#create_service_amount').val(row.data('amount'));
					$('#btn_add_service_create').html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
					$('#create_service_description').focus();
				}
				return false;
			});

		// DUPLICATE ELEMENT
			$(document).off('click', '.btn-duplicate-service-create').on('click', '.btn-duplicate-service-create', function(e) {
				e.stopPropagation();
				var old = $(this).closest('tr');
				var row = $(this).closest('tr').clone();
				row.removeClass('warning');
				row.data('description', old.data('description'));
				row.data('quantity', old.data('quantity'));
				row.data('amount', old.data('amount'));
				row.appendTo('#tbl_services_create tbody');
				$('#tbl_services_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#createPurchaseModal').data('bs.modal').handleUpdate();
				calculateCreatePurchaseTotal();
				return false;
			});

		// ADD PRODUCT TO TABLE
			$(document).off('click', '#btn_add_product_create').on('click', '#btn_add_product_create', function(e) {
				e.stopPropagation();

				var product_id = (isNaN( $('#create_product_id').val() ) || $('#create_product_id').val() == '' ? 0 : parseInt( $('#create_product_id').val() ));
				var quantity = (isNaN($('#create_product_quantity').val()) || $('#create_product_quantity').val() == '' ? 0 : $('#create_product_quantity').val());
				var amount = (isNaN($('#create_product_amount').val()) || $('#create_product_amount').val() == '' ? 0 : round_dec($('#create_product_amount').val(), 2));

				if (product_id > 0 && quantity > 0 && amount > 0) {
					var check = false;
					$('#tbl_products_create tbody tr').each(function (index) {
						if ($(this).data('product-id') == $('#create_product_id').val()) {
							check = true;
						}
					});
					if (check) {
						alert('El producto ya es parte de la compra.');
					} else {
						$('#tbl_products_create').find('tbody')
							.append(
								$('<tr>').attr('data-product-id', product_id).attr('data-quantity', quantity).attr('data-amount', amount)
									.append('<td>&nbsp;</td>')
									.append('<td>' + $('#create_product_id option:selected').text() + '</td>')
									.append('<td style="text-align: right;">' + quantity + '</td>')
									.append('<td style="text-align: right;">' + round_dec(amount / quantity, 2).formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + amount.formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right').attr('style', 'width: 140px;')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												//.append('<button class="btn btn-default btn-add-quantity-create"><span class="glyphicon glyphicon-chevron-up" aria-hidden="true"></span></button>')
												//.append('<button class="btn btn-default btn-substract-quantity-create"><span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-create"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#createPurchaseModal').data('bs.modal').handleUpdate();
						$('#tbl_products_create tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
						calculateCreatePurchaseTotal();
						$('#create_product_id').val(0).trigger('chosen:updated').trigger('chosen:activate');
						$('#create_product_quantity').val('');
						$('#create_product_amount').val('');
					}
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// ADD QUANTITY TO ELEMENT
			$(document).off('click', '.btn-add-quantity-create').on('click', '.btn-add-quantity-create', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = parseFloat(row.data('quantity'));
				var price = round_dec(row.data('price'), 2);
				quantity++;
				row.data('quantity', parseFloat(quantity));
				row.children('td').eq(2).text(quantity);
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateCreatePurchaseTotal();
				return false;
			});

		// SUBSTRACT QUANTITY TO ELEMENT
			$(document).off('click', '.btn-substract-quantity-create').on('click', '.btn-substract-quantity-create', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = parseFloat(row.data('quantity'));
				var price = round_dec(row.data('price'), 2);
				quantity--;
				if (quantity <= 0) {
					return false;
				}
				row.data('quantity', quantity);
				row.children('td').eq(2).text(quantity);
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateCreatePurchaseTotal();
				return false;
			});

		// REMOVE ELEMENT FROM TABLE
			$(document).off('click', '.btn-remove-element-create').on('click', '.btn-remove-element-create', function(e) {
				e.stopPropagation();
				$(this).closest('tr').remove();
				calculateCreatePurchaseTotal();
				$('#tbl_products_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#tbl_services_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#btn_add_service_create').data('option', 0);
				return false;
			});

		// SAVE PURCHASE
			$('.btn_purchase_create').on('click', function(e) {
				e.stopPropagation();
				var btn = $(this);
				if (btn.data('option') == 1) {
					if (!confirm('¿Seguro(a) qué desea procesar la compra?')) {
						return false;
					}
				}
				var products = [];
				$('#tbl_products_create tbody tr').each(function (index) {
					products[index] = {
						product_id: $(this).data('product-id'),
						quantity: $(this).data('quantity'),
						amount: $(this).data('amount')
					}
				});
				var services = [];
				$('#tbl_services_create tbody tr').each(function (index) {
					services[index] = {
						description: $(this).data('description'),
						quantity: $(this).data('quantity'),
						amount: $(this).data('amount')
					}
				});
				var data = {
					_token: btn.data('token'),
					date: $('#create_date').val(),
					department: $('#create_department').val(),
					supplier_id: $('#create_supplier_id').val(),
					type: $('#create_type').val(),
					term: $('#create_term').val(),
					invoice: $('#create_invoice').val(),
					cai: $('#create_cai').val(),
					annotations: $('#create_annotations').val(),
					products: products,
					services: services,
					freight: ((isNaN($('#create_freight').val()) || $('#create_freight').val() == '') ? 0 : round_dec($('#create_freight').val(), 2)),
					discount: ((isNaN($('#create_discount').val()) || $('#create_discount').val() == '') ? 0 : round_dec($('#create_discount').val(), 2)),
					exempt: $('#create_exempt:checked').size(),
					option: btn.data('option')
				}
				ejecutar_ajax('/compras/almacenar', 'html', data, btn, function(result) {
					$("#tbl_purchases tbody").prepend(result);
					$('.panel-footer small span').text($('#tbl_purchases tbody tr').length);
					$('#createPurchaseModal').modal('hide');
					toastr.success('Compra Creada');
				});
				return false;
			});

	// EDIT PURCHASE
		$(document).off('click', '.btnEditPurchaseModal').on('click', '.btnEditPurchaseModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#editPurchaseModal .modal-body').html('');
			ejecutar_ajax('/compras/' + button.data('purchase-id') + '/editar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#editPurchaseModal .modal-title .modal-title-id').text(button.data('number'));
				$('#editPurchaseModal .modal-body').html(result);
				$('#edit_supplier_id, #edit_product_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#edit_term, #edit_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				if ($('#edit_type').val() == 'Crédito') {
					$('#edit_term').prop('disabled', false);
				}
				sortTable('tbl_services_edit', true);
				sortTable('tbl_products_edit', true);
				$("#edit_exempt").bootstrapSwitch();
				calculateEditPurchaseTotal();
				$('#editPurchaseModal').modal('show');
			});
			return false;
		});

		$('#editPurchaseModal').on('shown.bs.modal', function (event) {
			$('#edit_supplier_id').trigger('chosen:activate');
		});

		$(document).on('change', '#edit_type', function() {
			if ($(this).val() == 'Crédito') {
				$('#edit_term').prop('disabled', false).focus();
			}

			if ($(this).val() == 'Contado') {
				$('#edit_term').prop('disabled', true);
			}
		});

		function calculateEditPurchaseTotal()
		{
			var total = 0;
			var total_products = 0;
			var total_services = 0;

			$('#tbl_services_edit tbody tr').each(function (index) {
				total_services += round_dec($(this).data('amount'), 2);
			});

			$('#tbl_products_edit tbody tr').each(function (index) {
				total_products += round_dec($(this).data('amount'), 2);
			});

			total = round_dec(total_products + total_services, 2);
			$('#edit_sub_total').val(total.formatMoney(2, ''));

			var discount = ((isNaN($('#edit_discount').val()) || $('#edit_discount').val() == '') ? 0 : $('#edit_discount').val());
			total -= round_dec(discount, 2);

			if ($('#edit_exempt').is(':checked')) {

				if (parseInt($('#edit_credit_notes').data('credit-note-counts')) > 0) {
					var credit_notes_total = round_dec($('#edit_credit_notes').data('credit-note-amount'), 2);
					total -= round_dec(credit_notes_total, 2);
				}
				$('#edit_tax').val('0.00');

			} else {

				var tax = 0;
				if (parseInt($('#edit_credit_notes').data('credit-note-counts')) > 0) {
					var credit_notes_total = round_dec($('#edit_credit_notes').data('credit-note-amount'), 2);
					if (parseInt($('#edit_credit_notes').data('mismo-mes')) === 1) {
						tax = (total - credit_notes_total) * round_dec($('#edit_tax').data('tax'), 2);
					} else {
						tax = total * round_dec($('#edit_tax').data('tax'), 2);
					}
					total = round_dec(total, 2) - round_dec(credit_notes_total, 2) + round_dec(tax, 2);
				} else {
					tax = total * round_dec($('#edit_tax').data('tax'), 2);
					total += round_dec(tax, 2);
				}

				$('#edit_tax').val(tax.formatMoney(2, ''));

			}

			var freight = ((isNaN($('#edit_freight').val()) || $('#edit_freight').val() == '') ? 0 : $('#edit_freight').val());
			total += round_dec(freight, 2);

			$('#edit_purchase_total').text(round_dec(total, 2).formatMoney(2, 'L '));
		}

		$(document).on('keyup', '#edit_discount', function(e){
			if (!isNaN($(this).val())) {
				calculateEditPurchaseTotal();
			}
		});

		$(document).on('keyup', '#edit_freight', function(e){
			if (!isNaN($(this).val())) {
				calculateEditPurchaseTotal();
			}
		});

		$(document).on('switchChange.bootstrapSwitch', '#edit_exempt', function(event, state) {
			calculateEditPurchaseTotal();
		});

		// SEARCH FOR EXISTING INVOICE NUMBER
		$(document).off('blur', '#edit_invoice').on('blur', '#edit_invoice', function() {
			var invoice = $(this);
			var supplier = $('#edit_supplier_id');
			var purchase = $('#edit_purchase_id');
			if (invoice && supplier) {
				var data = {
					type: 'edit',
					invoice: invoice.val(),
					supplier: supplier.val(),
					purchase: purchase.val(),
					_token: invoice.data('token')
				};
				ejecutar_ajax('/compras/factura_existente', 'json', data, null, function(result) {
					if (result.status == 1) {
						alert('El número de factura introducido ya existe en el sistema.');
					}
				});
			}
		});

		// ADD SERVICE TO TABLE
			$(document).off('click', '#btn_add_service_edit').on('click', '#btn_add_service_edit', function(e) {
				e.stopPropagation();

				var btn = $(this);
				var description = $('#edit_service_description').val().trim();
				var quantity = (isNaN($('#edit_service_quantity').val()) || $('#edit_service_quantity').val() == '' ? 0 : $('#edit_service_quantity').val());
				var amount = (isNaN($('#edit_service_amount').val()) || $('#edit_service_amount').val() == '' ? 0 : round_dec($('#edit_service_amount').val(), 2));

				if (description != '' && amount > 0 && quantity > 0) {
					if (btn.data('option') == 0) {
						$('#tbl_services_edit').find('tbody')
							.append(
								$('<tr>').attr('data-description', description).attr('data-quantity', quantity).attr('data-amount', amount)
									.append('<td>&nbsp;</td>')
									.append('<td>' + description + '</td>')
									.append('<td style="text-align: right;">' + quantity + '</td>')
									.append('<td style="text-align: right;">' + round_dec(amount / quantity, 2).formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + amount.formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-duplicate-service-edit"><span class="glyphicon glyphicon-duplicate" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-edit-service-edit"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-edit"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#tbl_services_edit tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
					} else {
						var row = $('#tbl_services_edit tbody tr.warning');
						row.data('description', description);
						row.data('quantity', quantity);
						row.data('amount', amount);
						row.children('td').eq(1).html(description);
						row.children('td').eq(2).text(quantity);
						row.children('td').eq(3).text(round_dec(amount / quantity, 2).formatMoney(2, ''));
						row.children('td').eq(4).text(amount.formatMoney(2, ''));
						row.removeClass('warning');
						btn.html('<span class="glyphicon glyphicon-plus" aria-hidden="true"></span>');
						btn.data('option', 0);
					}
					calculateEditPurchaseTotal();
					$('#editPurchaseModal').data('bs.modal').handleUpdate();
					$('#edit_service_description, #edit_service_quantity, #edit_service_amount').val('');
					$('#edit_service_description').focus();
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// EDIT ELEMENT
			$(document).off('click', '.btn-edit-service-edit').on('click', '.btn-edit-service-edit', function(e) {
				e.stopPropagation();
				if ($('#btn_add_service_edit').data('option') == 1) {
					alert('Ya existe un elemento en estado de edición.');
				} else {
					var row = $(this).closest('tr');
					row.addClass('warning');
					$('#btn_add_service_edit').data('option', 1);
					$('#edit_service_description').val(row.data('description'));
					$('#edit_service_quantity').val(row.data('quantity'));
					$('#edit_service_amount').val(row.data('amount'));
					$('#btn_add_service_edit').html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
					$('#edit_service_description').focus();
				}
				return false;
			});

		// DUPLICATE ELEMENT
			$(document).off('click', '.btn-duplicate-service-edit').on('click', '.btn-duplicate-service-edit', function(e) {
				e.stopPropagation();
				var old = $(this).closest('tr');
				var row = $(this).closest('tr').clone();
				row.removeClass('warning');
				row.data('description', old.data('description'));
				row.data('quantity', old.data('quantity'));
				row.data('amount', old.data('amount'));
				row.appendTo('#tbl_services_edit tbody');
				$('#tbl_services_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#editPurchaseModal').data('bs.modal').handleUpdate();
				calculateEditPurchaseTotal();
				return false;
			});

		// ADD PRODUCT TO TABLE
			$(document).off('click', '#btn_add_product_edit').on('click', '#btn_add_product_edit', function(e) {
				e.stopPropagation();

				var product_id = ( isNaN( $('#edit_product_id').val() ) || $('#edit_product_id').val() == '' ? 0 : parseInt( $('#edit_product_id').val() ) );
				var quantity = (isNaN($('#edit_product_quantity').val()) || $('#edit_product_quantity').val() == '' ? 0 : $('#edit_product_quantity').val());
				var amount = (isNaN($('#edit_product_amount').val()) || $('#edit_product_amount').val() == '' ? 0 : round_dec($('#edit_product_amount').val(), 2));

				if (product_id > 0 && quantity > 0 && amount > 0) {
					var check = false;
					$('#tbl_products_edit tbody tr').each(function (index) {
						if ($(this).data('product-id') == $('#edit_product_id').val()) {
							check = true;
						}
					});
					if (check) {
						alert('El producto ya es parte de la compra.');
					} else {
						$('#tbl_products_edit').find('tbody')
							.append(
								$('<tr>').attr('data-product-id', product_id).attr('data-quantity', quantity).attr('data-amount', amount)
									.append('<td>&nbsp;</td>')
									.append('<td>' + $('#edit_product_id option:selected').text() + '</td>')
									.append('<td style="text-align: right;">' + quantity + '</td>')
									.append('<td style="text-align: right;">' + round_dec(amount / quantity, 2).formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + amount.formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right').attr('style', 'width: 140px;')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												//.append('<button class="btn btn-default btn-add-quantity-edit"><span class="glyphicon glyphicon-chevron-up" aria-hidden="true"></span></button>')
												//.append('<button class="btn btn-default btn-substract-quantity-edit"><span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-edit"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#editPurchaseModal').data('bs.modal').handleUpdate();
						$('#tbl_products_edit tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
						calculateEditPurchaseTotal();
						$('#edit_product_id').val(0).trigger('chosen:updated').trigger('chosen:activate');
						$('#edit_product_quantity').val('');
						$('#edit_product_amount').val('');
					}
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// ADD QUANTITY TO ELEMENT
			$(document).off('click', '.btn-add-quantity-edit').on('click', '.btn-add-quantity-edit', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity++;
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateEditPurchaseTotal();
				return false;
			});

		// SUBSTRACT QUANTITY TO ELEMENT
			$(document).off('click', '.btn-substract-quantity-edit').on('click', '.btn-substract-quantity-edit', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity--;
				if (quantity <= 0) {
					return false;
				}
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateEditPurchaseTotal();
				return false;
			});

		// REMOVE ELEMENT FROM TABLE
			$(document).off('click', '.btn-remove-element-edit').on('click', '.btn-remove-element-edit', function(e) {
				e.stopPropagation();
				$(this).closest('tr').remove();
				$('#tbl_products_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#tbl_services_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				calculateEditPurchaseTotal();
				$('#btn_add_service_edit').data('option', 0);
				return false;
			});

		// UPDATE PURCHASE
			$('.btn_purchase_edit').on('click', function(e) {
				e.stopPropagation();
				var btn = $(this);
				if (btn.data('option') == 1) {
					if (!confirm('¿Seguro(a) qué desea generar la compra?')) {
						return false;
					}
				}
				var products = [];
				$('#tbl_products_edit tbody tr').each(function (index) {
					products[index] = {
						product_id: $(this).data('product-id'),
						quantity: $(this).data('quantity'),
						amount: $(this).data('amount')
					}
				});
				var services = [];
				$('#tbl_services_edit tbody tr').each(function (index) {
					services[index] = {
						description: $(this).data('description'),
						quantity: $(this).data('quantity'),
						amount: $(this).data('amount'),
					}
				});
				var data = {
					_token: btn.data('token'),
					_method: 'PUT',
					date: $('#edit_date').val(),
					supplier_id: $('#edit_supplier_id').val(),
					department: $('#edit_department').val(),
					type: $('#edit_type').val(),
					term: $('#edit_term').val(),
					invoice: $('#edit_invoice').val(),
					cai: $('#edit_cai').val(),
					annotations: $('#edit_annotations').val(),
					services: services,
					products: products,
					freight: ((isNaN($('#edit_freight').val()) || $('#edit_freight').val() == '') ? 0 : round_dec($('#edit_freight').val(), 2)),
					discount: ((isNaN($('#edit_discount').val()) || $('#edit_discount').val() == '') ? 0 : round_dec($('#edit_discount').val(), 2)),
					exempt: $('#edit_exempt:checked').size(),
					option: btn.data('option'),
				}
				ejecutar_ajax('/compras/' + $('#edit_purchase_id').val(), 'html', data, btn, function(result) {
					var row = $("#tbl_purchases tbody").find("tr[data-purchase-id='" + $('#edit_purchase_id').val() + "']");
					row.html(result).addClass('warning');
					$('#editPurchaseModal').modal('hide');
					toastr.info('Datos Actualizados')
				});
				return false;
			});

	// DELETE PURCHASE
		$('#deletePurchaseModal').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			$("#deletePurchaseModal .modal-body span").text(button.data('number'));
			$('#btn_purchases_delete').data('purchase-id', button.data('purchase-id'));
		});

		$('#btn_purchases_delete').on('click', function(e) {
			e.stopPropagation();
			var purchase_id = $(this).data('purchase-id');
			var datos = {
				_token: $(this).data('token'),
				_method: 'DELETE'
			}
			ejecutar_ajax('/compras/' + purchase_id, 'json', datos, $(this), function(result) {
				if (result == 1) {
					$("#tbl_purchases tbody").find("tr[data-purchase-id='" + purchase_id + "']").remove();
					$('.panel-footer small span').text($('#tbl_purchases tbody tr').length);
					$('#deletePurchaseModal').modal('hide');
					toastr.warning('Compra Eliminada');
				}
			});
			return false;
		});

	// SHOW PURCHASE
		$(document).off('click', '.btnShowPurchaseModal').on('click', '.btnShowPurchaseModal', function(e) {
			e.stopPropagation();
			var button = $(this);
			$('#showPurchaseModal .modal-body').html('');
			ejecutar_ajax('/compras/' + button.data('purchase-id'), 'html', { _token: button.data('token') }, button, function(result) {
				$('#showPurchaseModal .modal-body').html(result);
				$('#showPurchaseModal .modal-title .modal-title-id').text(button.data('number'));
				$('#showPurchaseModal').modal('show');
			});
			return false;
		});

	// DUPLICATE PURCHASE
		$(document).off('click', '#btnDuplicatePurchase').on('click', '#btnDuplicatePurchase', function(e) {
			e.stopPropagation();
			var button = $(this);
			ejecutar_ajax('/compras/' + button.data('purchase-id') + '/duplicar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#showPurchaseModal').modal('hide');
				$('#tbl_purchases tbody').prepend(result);
				$('.panel-footer small span').text($('#tbl_purchases tbody tr').length);
				toastr.info('Compra Duplicada');
			});
			return false;
		});

	// E-MAIL PURCHASE
		$('#emailPurchaseModal').on('show.bs.modal', function (event) {
			$('#txt_email_to, txt_email_subject, #txt_email_message').val('');
			if (CKEDITOR.instances.txt_email_message) {
				CKEDITOR.instances.txt_email_message.destroy();
			}
			CKEDITOR.replace('txt_email_message', { language: 'es' });
			CKEDITOR.instances.txt_email_message.setData('');
			var button = $(event.relatedTarget); // Button that triggered the modal
			$('#emailPurchaseModal .modal-title-id').text(button.data('number'));
			$('#txt_email_to').val(button.data('email'));
			$('#txt_email_subject').val('Compra #' + button.data('number'));
			$('#btn_purchases_email').data('purchase-id', button.data('purchase-id'));
		});

		$('#emailPurchaseModal').on('shown.bs.modal', function (event) {
			$('#txt_email_to').focus();
		});

		$(document).off('click', '#btn_purchases_email').on('click', '#btn_purchases_email', function(e) {
			e.stopPropagation();
			var btn = $(this);
			var data = {
				_token: btn.data('token'),
				to: $('#txt_email_to').val(),
				subject: $('#txt_email_subject').val(),
				msg: CKEDITOR.instances['txt_email_message'].getData()
			}
			ejecutar_ajax('/compras/' + btn.data('purchase-id') + '/email', 'json', data, btn, function(result) {
				if (result == 1) {
					$('#emailPurchaseModal').modal('hide');
					toastr.info('Compra Enviada');
				}
			});
			return false;
		});

	// PROCESS PURCHASE
		$(document).off('click', '#btnProcessPurchase').on('click', '#btnProcessPurchase', function(e) {
			e.stopPropagation();
			var button = $(this);
			$('#processPurchaseModal .modal-body').html('');
			ejecutar_ajax('/compras/' + button.data('purchase-id') + '/preprocesar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#processPurchaseModal .modal-body').html(result);
				$('#process_purchase_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				$('#process_purchase_cai').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#showPurchaseModal').modal('hide');
				$('#processPurchaseModal').modal('show');
			});
			return false;
		});

		$('#processPurchaseModal').on('shown.bs.modal', function (event) {
			$('#process_purchase_invoice').focus();
		});

		$(document).off('click', '.btn_purchase_process').on('click', '.btn_purchase_process', function(e) {
			e.stopPropagation();
			if (confirm('¿Seguro(a) qué desea procesar la compra?')) {
				var btn = $(this);
				ejecutar_ajax('/compras/' + $('#process_purchase_id').val() + '/procesar', 'html', $('#frm_purchase_process').serialize(), btn, function(result) {
					var row = $("#tbl_purchases tbody").find("tr[data-purchase-id='" + $('#process_purchase_id').val() + "']");
					row.html(result).addClass('warning');
					$('#processPurchaseModal').modal('hide');
					toastr.info('Compra Procesada')
				});
			}
			return false;
		});

	// SEARCH PURCHASES
		$('#txt_purchase_supplier_search, #txt_purchase_state_search, #txt_purchase_month_search, #txt_purchase_year_search').chosen({
			no_results_text: 'No se encontraron resultados para',
			allow_single_deselect: true,
			search_contains: true
		});

		$('.btn_purchases_search').on('click', function(e) {
			e.stopPropagation();
			var btn = $(this);

			if (btn.data('option') == 1 && $('#txt_purchase_supplier_search').val() == '') {
				$('#txt_purchase_supplier_search').trigger('chosen:activate');
				return false;
			}

			if (btn.data('option') == 2 && $('#txt_purchase_state_search').val() == '') {
				$('#txt_purchase_state_search').trigger('chosen:activate');
				return false;
			}

			if (btn.data('option') == 3 && ($('#txt_purchase_month_search').val() == '' || $('#txt_purchase_year_search').val() == '')) {
				alert('Fecha incompleta.');
				return false;
			}

			var data = {
				_method: 'POST',
				_token: btn.data('token'),
				supplier_id: $('#txt_purchase_supplier_search').val(),
				state: $('#txt_purchase_state_search').val(),
				month: $('#txt_purchase_month_search').val(),
				year: $('#txt_purchase_year_search').val(),
				option: btn.data('option')
			};
			ejecutar_ajax('/compras/busqueda', 'html', data, btn, function(result) {
				$('#searchModal').modal('hide');
				$("#tbl_purchases tbody").hide().html(result).fadeIn('slow');
				$('.panel-footer small span').text($("#tbl_purchases tbody tr").length);
				var total = $("#tbl_purchases tbody tr").length;
				if (total == 0) {
					toastr.error('No se Encontraron Compras');
				} else {
					toastr.info(total + ' Compra(s) Encontrada(s)');
				}
			});

			return false;
		});

	// EXPORT PURCHASES
		$('#txt_purchase_month_export, #txt_purchase_year_export, #txt_purchase_department_export').chosen({
			no_results_text: 'No se encontraron resultados para',
			allow_single_deselect: true,
			export_contains: true
		});

/*
	// CREDIT NOTES
		$(document).off('click', '.btnCreditNotesPurchaseModal').on('click', '.btnCreditNotesPurchaseModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#creditNotesPurchaseModal .modal-body').html('');
			ejecutar_ajax('/compras/' + button.data('purchase-id') + '/notas_de_credito', 'html', { _token: button.data('token') }, button, function(result) {
				$('#editPurchaseModal .modal-title .modal-title-id').text(button.data('number'));
				$('#editPurchaseModal .modal-body').html(result);
				$('#edit_supplier_id, #edit_product_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#edit_term, #edit_date').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				if ($('#edit_type').val() == 'Crédito') {
					$('#edit_term').prop('disabled', false);
				}
				sortTable('tbl_services_edit', true);
				sortTable('tbl_products_edit', true);
				$("#edit_exempt").bootstrapSwitch();
				calculateEditPurchaseTotal();
				$('#editPurchaseModal').modal('show');
			});
			return false;
		});

		$('#editPurchaseModal').on('shown.bs.modal', function (event) {
			$('#edit_supplier_id').trigger('chosen:activate');
		});

		$(document).on('change', '#edit_type', function() {
			if ($(this).val() == 'Crédito') {
				$('#edit_term').prop('disabled', false).focus();
			}

			if ($(this).val() == 'Contado') {
				$('#edit_term').prop('disabled', true);
			}
		});

		function calculateEditPurchaseTotal()
		{
			var total = 0;
			var total_products = 0;
			var total_services = 0;

			$('#tbl_services_edit tbody tr').each(function (index) {
				total_services += round_dec($(this).data('amount'), 2);
			});

			$('#tbl_products_edit tbody tr').each(function (index) {
				total_products += round_dec($(this).data('amount'), 2);
			});

			total = round_dec(total_products + total_services, 2);
			$('#edit_sub_total').val(total.formatMoney(2, ''));

			var discount = ((isNaN($('#edit_discount').val()) || $('#edit_discount').val() == '') ? 0 : $('#edit_discount').val());
			total -= round_dec(discount, 2);

			var freight = ((isNaN($('#edit_freight').val()) || $('#edit_freight').val() == '') ? 0 : $('#edit_freight').val());
			total += round_dec(freight, 2);

			if ($('#edit_exempt').is(':checked')) {
				$('#edit_tax').val('0.00');
			} else {
				var tax = total * round_dec($('#edit_tax').data('tax'), 2);
				$('#edit_tax').val(tax.formatMoney(2, ''));
				total += round_dec(tax, 2);
			}

			$('#edit_purchase_total').text(round_dec(total, 2).formatMoney(2, 'L '));
		}

		$(document).on('keyup', '#edit_discount', function(e){
			if (!isNaN($(this).val())) {
				calculateEditPurchaseTotal();
			}
		});

		$(document).on('keyup', '#edit_freight', function(e){
			if (!isNaN($(this).val())) {
				calculateEditPurchaseTotal();
			}
		});

		$(document).on('switchChange.bootstrapSwitch', '#edit_exempt', function(event, state) {
			calculateEditPurchaseTotal();
		});

		// ADD SERVICE TO TABLE
			$(document).off('click', '#btn_add_service_edit').on('click', '#btn_add_service_edit', function(e) {
				e.stopPropagation();

				var btn = $(this);
				var description = $('#edit_service_description').val().trim();
				var quantity = (isNaN($('#edit_service_quantity').val()) || $('#edit_service_quantity').val() == '' ? 0 : $('#edit_service_quantity').val());
				var amount = (isNaN($('#edit_service_amount').val()) || $('#edit_service_amount').val() == '' ? 0 : round_dec($('#edit_service_amount').val(), 2));

				if (description != '' && amount > 0 && quantity > 0) {
					if (btn.data('option') == 0) {
						$('#tbl_services_edit').find('tbody')
							.append(
								$('<tr>').attr('data-description', description).attr('data-quantity', quantity).attr('data-amount', amount)
									.append('<td>&nbsp;</td>')
									.append('<td>' + description + '</td>')
									.append('<td style="text-align: right;">' + quantity + '</td>')
									.append('<td style="text-align: right;">' + round_dec(amount / quantity, 2).formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + amount.formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-duplicate-service-edit"><span class="glyphicon glyphicon-duplicate" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-edit-service-edit"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-edit"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#tbl_services_edit tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
					} else {
						var row = $('#tbl_services_edit tbody tr.warning');
						row.data('description', description);
						row.data('quantity', quantity);
						row.data('amount', amount);
						row.children('td').eq(1).html(description);
						row.children('td').eq(2).text(quantity);
						row.children('td').eq(3).text(round_dec(amount / quantity, 2).formatMoney(2, ''));
						row.children('td').eq(4).text(amount.formatMoney(2, ''));
						row.removeClass('warning');
						btn.html('<span class="glyphicon glyphicon-plus" aria-hidden="true"></span>');
						btn.data('option', 0);
					}
					calculateEditPurchaseTotal();
					$('#editPurchaseModal').data('bs.modal').handleUpdate();
					$('#edit_service_description, #edit_service_quantity, #edit_service_amount').val('');
					$('#edit_service_description').focus();
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// EDIT ELEMENT
			$(document).off('click', '.btn-edit-service-edit').on('click', '.btn-edit-service-edit', function(e) {
				e.stopPropagation();
				if ($('#btn_add_service_edit').data('option') == 1) {
					alert('Ya existe un elemento en estado de edición.');
				} else {
					var row = $(this).closest('tr');
					row.addClass('warning');
					$('#btn_add_service_edit').data('option', 1);
					$('#edit_service_description').val(row.data('description'));
					$('#edit_service_quantity').val(row.data('quantity'));
					$('#edit_service_amount').val(row.data('amount'));
					$('#btn_add_service_edit').html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
					$('#edit_service_description').focus();
				}
				return false;
			});

		// DUPLICATE ELEMENT
			$(document).off('click', '.btn-duplicate-service-edit').on('click', '.btn-duplicate-service-edit', function(e) {
				e.stopPropagation();
				var old = $(this).closest('tr');
				var row = $(this).closest('tr').clone();
				row.removeClass('warning');
				row.data('description', old.data('description'));
				row.data('quantity', old.data('quantity'));
				row.data('amount', old.data('amount'));
				row.appendTo('#tbl_services_edit tbody');
				$('#tbl_services_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#editPurchaseModal').data('bs.modal').handleUpdate();
				calculateEditPurchaseTotal();
				return false;
			});

		// ADD PRODUCT TO TABLE
			$(document).off('click', '#btn_add_product_edit').on('click', '#btn_add_product_edit', function(e) {
				e.stopPropagation();

				var product_id = ( isNaN( $('#edit_product_id').val() ) || $('#edit_product_id').val() == '' ? 0 : parseInt( $('#edit_product_id').val() ) );
				var quantity = (isNaN($('#edit_product_quantity').val()) || $('#edit_product_quantity').val() == '' ? 0 : $('#edit_product_quantity').val());
				var amount = (isNaN($('#edit_product_amount').val()) || $('#edit_product_amount').val() == '' ? 0 : round_dec($('#edit_product_amount').val(), 2));

				if (product_id > 0 && quantity > 0 && amount > 0) {
					var check = false;
					$('#tbl_products_edit tbody tr').each(function (index) {
						if ($(this).data('product-id') == $('#edit_product_id').val()) {
							check = true;
						}
					});
					if (check) {
						alert('El producto ya es parte de la compra.');
					} else {
						$('#tbl_products_edit').find('tbody')
							.append(
								$('<tr>').attr('data-product-id', product_id).attr('data-quantity', quantity).attr('data-amount', amount)
									.append('<td>&nbsp;</td>')
									.append('<td>' + $('#edit_product_id option:selected').text() + '</td>')
									.append('<td style="text-align: right;">' + quantity + '</td>')
									.append('<td style="text-align: right;">' + round_dec(amount / quantity, 2).formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + amount.formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right').attr('style', 'width: 140px;')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												//.append('<button class="btn btn-default btn-add-quantity-edit"><span class="glyphicon glyphicon-chevron-up" aria-hidden="true"></span></button>')
												//.append('<button class="btn btn-default btn-substract-quantity-edit"><span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-edit"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#editPurchaseModal').data('bs.modal').handleUpdate();
						$('#tbl_products_edit tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
						calculateEditPurchaseTotal();
						$('#edit_product_id').val(0).trigger('chosen:updated').trigger('chosen:activate');
						$('#edit_product_quantity').val('');
						$('#edit_product_amount').val('');
					}
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// ADD QUANTITY TO ELEMENT
			$(document).off('click', '.btn-add-quantity-edit').on('click', '.btn-add-quantity-edit', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity++;
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateEditPurchaseTotal();
				return false;
			});

		// SUBSTRACT QUANTITY TO ELEMENT
			$(document).off('click', '.btn-substract-quantity-edit').on('click', '.btn-substract-quantity-edit', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity--;
				if (quantity <= 0) {
					return false;
				}
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateEditPurchaseTotal();
				return false;
			});

		// REMOVE ELEMENT FROM TABLE
			$(document).off('click', '.btn-remove-element-edit').on('click', '.btn-remove-element-edit', function(e) {
				e.stopPropagation();
				$(this).closest('tr').remove();
				$('#tbl_products_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#tbl_services_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				calculateEditPurchaseTotal();
				$('#btn_add_service_edit').data('option', 0);
				return false;
			});

		// UPDATE PURCHASE
			$('.btn_purchase_edit').on('click', function(e) {
				e.stopPropagation();
				var btn = $(this);
				if (btn.data('option') == 1) {
					if (!confirm('¿Seguro(a) qué desea generar la compra?')) {
						return false;
					}
				}
				var products = [];
				$('#tbl_products_edit tbody tr').each(function (index) {
					products[index] = {
						product_id: $(this).data('product-id'),
						quantity: $(this).data('quantity'),
						amount: $(this).data('amount')
					}
				});
				var services = [];
				$('#tbl_services_edit tbody tr').each(function (index) {
					services[index] = {
						description: $(this).data('description'),
						quantity: $(this).data('quantity'),
						amount: $(this).data('amount'),
					}
				});
				var data = {
					_token: btn.data('token'),
					_method: 'PUT',
					date: $('#edit_date').val(),
					supplier_id: $('#edit_supplier_id').val(),
					type: $('#edit_type').val(),
					term: $('#edit_term').val(),
					invoice: $('#edit_invoice').val(),
					cai: $('#edit_cai').val(),
					annotations: $('#edit_annotations').val(),
					services: services,
					products: products,
					freight: ((isNaN($('#edit_freight').val()) || $('#edit_freight').val() == '') ? 0 : round_dec($('#edit_freight').val(), 2)),
					discount: ((isNaN($('#edit_discount').val()) || $('#edit_discount').val() == '') ? 0 : round_dec($('#edit_discount').val(), 2)),
					exempt: $('#edit_exempt:checked').size(),
					option: btn.data('option'),
				}
				ejecutar_ajax('/compras/' + $('#edit_purchase_id').val(), 'html', data, btn, function(result) {
					var row = $("#tbl_purchases tbody").find("tr[data-purchase-id='" + $('#edit_purchase_id').val() + "']");
					row.html(result).addClass('warning');
					$('#editPurchaseModal').modal('hide');
					toastr.info('Datos Actualizados')
				});
				return false;
			});
*/
}

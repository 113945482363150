if ($('#pg_purchases').length > 0) {

	// CREDIT NOTES
		$(document).off('click', '.btnCreditNotesPurchaseModal').on('click', '.btnCreditNotesPurchaseModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#creditNotesPurchaseModal .modal-body').html('');
			ejecutar_ajax('/compras/' + button.data('purchase-id') + '/notas_de_credito', 'html', { _token: button.data('token') }, button, function(result) {
				$('#creditNotesPurchaseModal .modal-body').html(result);
				$('#credit_note_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#creditNotesPurchaseModal').modal('show');
			});
			return false;
		});

		$('#creditNotesPurchaseModal').on('shown.bs.modal', function (event) {
			$('#credit_note_id').trigger('chosen:activate');
		});

		$(document).on('change', '#credit_note_id', function() {
			$('#credit_note_amount').val('').prop('disabled', true);
			$('.btnAddAsociationCredit').prop('disabled', true);
			var credit = $(this);
			if (credit.val() != '') {
				$('#credit_note_amount').val(credit.find(':selected').data('available')).prop('disabled', false);
				$('.btnAddAsociationCredit').prop('disabled', false);
			}
		});

		$(document).off('click', '.btnAddAsociationCredit').on('click', '.btnAddAsociationCredit', function(e) {
			e.stopPropagation();
			var btn = $(this);
			var data = {
				_token: btn.data('token'),
				credit_note_id: $('#credit_note_id').val(),
				amount: $('#credit_note_amount').val()
			}
			ejecutar_ajax('/compras/' + btn.data('purchase-id') + '/notas_de_credito/almacenar', 'html', data, btn, function (result)
			{
				$('#credit_note_id').val('').trigger('chosen:updated').trigger('chosen:activate');
				$('#credit_note_amount').val('').prop('disabled', true);
				$('.btnAddAsociationCredit').prop('disabled', true);
				$('#tbl_purchase_credit_notes tbody').append(result);
				$('#tbl_purchase_credit_notes tbody tr').each(function (index)
				{
					$(this).children('td').eq(0).text(index + 1);
				});
				var row = $('#tbl_purchases tbody tr[data-purchase-id="' + btn.data('purchase-id') + '"]');
				var count = (isNaN(parseInt(row.children('td').eq(4).children('span').eq(0).text())) ? 0 : parseInt(row.children('td').eq(4).children('span').eq(0).text()));
				row.children('td').eq(4).children('span').eq(0).text(count + 1);
				toastr.info('Nota de crédito asociada satisfactoriamente.');
			});
			return false;
		});

		$(document).off('click', '.btnDeleteAsociationCredit').on('click', '.btnDeleteAsociationCredit', function(e) {
			e.stopPropagation();
			if (confirm('¿Seguro(a) qué desea remover la nota de crédito seleccionada?')) {
				var btn = $(this);
				var data = {
					_token: btn.data('token'),
					_method: 'DELETE'
				}
				ejecutar_ajax('/compras/notas_de_credito/' + btn.data('asociation-id') + '/remover', 'html', data, btn, function (result)
				{
					var row = $('#tbl_purchases tbody tr[data-purchase-id="' + btn.data('purchase-id') + '"]');
					var count = (isNaN(parseInt(row.children('td').eq(4).children('span').eq(0).text())) ? 0 : parseInt(row.children('td').eq(4).children('span').eq(0).text()));
					row.children('td').eq(4).children('span').eq(0).text(count - 1);

					btn.closest('tr').remove();
					$('#tbl_purchase_credit_notes tbody tr').each(function (index)
					{
						$(this).children('td').eq(0).text(index + 1);
					});
					toastr.warning('Nota de crédito removida satisfactoriamente.');
				});
			}
			return false;
		});

}

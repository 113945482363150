if ($('#pg_proforms').length > 0) {

	$(".btn_menu_proforms").addClass('active');
	$(".btn_menu_proforms").closest('.dropdown').addClass('active');

	// CREATE PROFORM
		$('#btnCreateProformModal').on('click', function(e){
			e.stopPropagation();
			$('#createProformModal .modal-body').html('');
			ejecutar_ajax('/proformas/crear', 'html', { _token: $(this).data('token') }, $(this), function(result) {
				$('#createProformModal .modal-body').html(result);
				$('#create_customer_id, #create_product_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#create_date, #create_expire').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				sortTable('tbl_services_create', true);
				sortTable('tbl_products_create', true);
				$("#create_exempt").bootstrapSwitch();
				$('#createProformModal').modal('show');
			});
			return false;
		});

		$('#createProformModal').on('shown.bs.modal', function (event) {
			$('#create_customer_id').trigger('chosen:activate');
		});

		function calculateCreateProformTotal()
		{
			var total_proform = 0;

			$('#tbl_products_create tbody tr').each(function (index) {
				total_proform += round_dec(round_dec($(this).data('quantity'), 2) * round_dec($(this).data('price'), 2), 2);
			});
			//$('#txt_total_products_create').text((total_products).formatMoney());

			$('#tbl_services_create tbody tr').each(function (index) {
				total_proform += round_dec(round_dec($(this).data('quantity'), 2) * round_dec($(this).data('price'), 2), 2);
			});
			//$('#txt_total_services_create').text((total_services).formatMoney());

			$('#create_sub_total').val(total_proform.formatMoney(2, ''));

			var discount_percentage = (isNaN($('#create_discount').val()) || $('#create_discount').val() == '' ? 0 : $('#create_discount').val());
			var discount = round_dec(total_proform * (parseFloat(discount_percentage) / 100), 2);
			$('#create_discount_total').val(discount.formatMoney(2, ''));
			total_proform -= discount;

			var freight = (isNaN($('#create_freight').val()) || $('#create_freight').val() == '' ? 0 : round_dec($('#create_freight').val(), 2));
			total_proform += freight;

			if ($('#create_exempt').is(':checked')) {
				$('#create_tax').val('0.00');
			} else {
				var tax = round_dec(total_proform * parseFloat($('#create_tax').data('tax')), 2);
				$('#create_tax').val(tax.formatMoney(2, ''));
				total_proform += tax;
			}

			$('#create_proform_total').text(round_dec(total_proform, 2).formatMoney(2, 'L '));
		}

		$(document).on('keyup', '#create_discount', function(e){
			if (!isNaN($(this).val())) {
				calculateCreateProformTotal();
			}
		});

		$(document).on('keyup', '#create_freight', function(e){
			if (!isNaN($(this).val())) {
				calculateCreateProformTotal();
			}
		});

		$(document).on('switchChange.bootstrapSwitch', '#create_exempt', function(event, state) {
			calculateCreateProformTotal();
		});

		$(document).on('change', '#create_product_id', function() {
			if ($(this).val() == 0) {
				$('#create_product_price').val('');
			} else {
				$('#create_product_price').val(round_dec($('#create_product_id option:selected').data('price'), 2).formatMoney(2, ''));
				$('#create_product_quantity').focus();
			}
		});

		// ADD SERVICE TO TABLE
			$(document).off('click', '#btn_add_service_create').on('click', '#btn_add_service_create', function(e) {
				e.stopPropagation();

				var btn = $(this);
				var description = $('#create_service_description').val().trim();
				var quantity = (isNaN($('#create_service_quantity').val()) || $('#create_service_quantity').val() == '' ? 0 : round_dec($('#create_service_quantity').val(), 2));
				var price = (isNaN($('#create_service_price').val()) || $('#create_service_price').val() == '' ? 0 : round_dec($('#create_service_price').val(), 2));

				if (description != '' && price > 0 && quantity > 0) {
					if (btn.data('option') == 0) {
						$('#tbl_services_create').find('tbody')
							.append(
								$('<tr>').attr('data-description', description).attr('data-quantity', quantity).attr('data-price', price)
									.append('<td>&nbsp;</td>')
									.append('<td>' + description + '</td>')
									.append('<td style="text-align: right;">' + quantity.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + price.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + round_dec(price * quantity, 2).formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-duplicate-service-create"><span class="glyphicon glyphicon-duplicate" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-edit-service-create"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-create"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#tbl_services_create tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
					} else {
						var row = $('#tbl_services_create tbody tr.warning');
						row.data('description', description);
						row.data('quantity', quantity);
						row.data('price', price);
						row.children('td').eq(1).html(description);
						row.children('td').eq(2).text(quantity.formatMoney(2, ''));
						row.children('td').eq(3).text(price.formatMoney(2, ''));
						row.children('td').eq(4).text(round_dec(price * quantity, 2).formatMoney(2, ''));
						row.removeClass('warning');
						btn.data('option', 0);
						btn.html('<span class="glyphicon glyphicon-plus" aria-hidden="true"></span>');
					}
					calculateCreateProformTotal();
					$('#createProformModal').data('bs.modal').handleUpdate();
					$('#create_service_description, #create_service_quantity, #create_service_price').val('');
					$('#create_service_unit').val('').trigger('chosen:updated');
					$('#create_service_description').focus();
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// EDIT ELEMENT
			$(document).off('click', '.btn-edit-service-create').on('click', '.btn-edit-service-create', function(e) {
				e.stopPropagation();
				if ($('#btn_add_service_create').data('option') == 1) {
					alert('Ya existe un elemento en estado de edición.');
				} else {
					var row = $(this).closest('tr');
					row.addClass('warning');
					$('#btn_add_service_create').data('option', 1);
					$('#create_service_description').val(row.data('description'));
					$('#create_service_quantity').val(row.data('quantity'));
					$('#create_service_price').val(row.data('price'));
					$('#btn_add_service_create').html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
					$('#create_service_description').focus();
				}
				return false;
			});

		// DUPLICATE ELEMENT
			$(document).off('click', '.btn-duplicate-service-create').on('click', '.btn-duplicate-service-create', function(e) {
				e.stopPropagation();
				var old = $(this).closest('tr');
				var row = $(this).closest('tr').clone();
				row.removeClass('warning');
				row.data('description', old.data('description'));
				row.data('quantity', old.data('quantity'));
				row.data('price', old.data('price'));
				row.appendTo('#tbl_services_create tbody');
				$('#tbl_services_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#createProformModal').data('bs.modal').handleUpdate();
				calculateCreateProformTotal();
				return false;
			});

		// ADD PRODUCT TO TABLE
			$(document).off('click', '#btn_add_product_create').on('click', '#btn_add_product_create', function(e) {
				e.stopPropagation();

				var product_id = ( isNaN( $('#create_product_id').val() ) || $('#create_product_id').val() == '' ? 0 : parseInt( $('#create_product_id').val() ) );
				var quantity = (isNaN($('#create_product_quantity').val()) || $('#create_product_quantity').val() == '' ? 0 : round_dec($('#create_product_quantity').val(), 2));
				var price = (isNaN($('#create_product_id option:selected').data('price')) || $('#create_product_id option:selected').data('price') == '' ? 0 : round_dec($('#create_product_id option:selected').data('price'), 2));

				if (product_id > 0 && quantity > 0 && price > 0) {
					var check = false;
					$('#tbl_products_create tbody tr').each(function (index) {
						if ($(this).data('product-id') == $('#create_product_id').val()) {
							check = true;
						}
					});
					if (check) {
						alert('El producto ya es parte de la proforma.');
					} else {
						$('#tbl_products_create').find('tbody')
							.append(
								$('<tr>').attr('data-product-id', product_id).attr('data-quantity', quantity).attr('data-price', price)
									.append('<td>&nbsp;</td>')
									.append('<td>' + $('#create_product_id option:selected').text() + '</td>')
									.append('<td style="text-align: right;">' + quantity.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + price.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + round_dec(quantity * price, 2).formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right').attr('style', 'width: 140px;')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-add-quantity-create"><span class="glyphicon glyphicon-chevron-up" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-substract-quantity-create"><span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-create"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#createProformModal').data('bs.modal').handleUpdate();
						$('#tbl_products_create tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
						calculateCreateProformTotal();
						$('#create_product_id').val(0).trigger('chosen:updated').trigger('chosen:activate');
						$('#create_product_quantity').val('');
						$('#create_product_price').val('');
					}
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// ADD QUANTITY TO ELEMENT
			$(document).off('click', '.btn-add-quantity-create').on('click', '.btn-add-quantity-create', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity++;
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateCreateProformTotal();
				return false;
			});

		// SUBSTRACT QUANTITY TO ELEMENT
			$(document).off('click', '.btn-substract-quantity-create').on('click', '.btn-substract-quantity-create', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity--;
				if (quantity <= 0) {
					return false;
				}
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateCreateProformTotal();
				return false;
			});

		// REMOVE ELEMENT FROM TABLE
			$(document).off('click', '.btn-remove-element-create').on('click', '.btn-remove-element-create', function(e) {
				e.stopPropagation();
				$(this).closest('tr').remove();
				calculateCreateProformTotal();
				$('#tbl_products_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#tbl_services_create tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#btn_add_service_create').data('option', 0);
				return false;
			});

		// SAVE PROFORM
			$('.btn_proform_create').on('click', function(e) {
				e.stopPropagation();
				var btn = $(this);
				if (btn.data('option') == 1) {
					if (!confirm('¿Seguro(a) qué desea procesar la proforma?')) {
						return false;
					}
				}
				var products = [];
				$('#tbl_products_create tbody tr').each(function (index) {
					products[index] = {
						product_id: $(this).data('product-id'),
						quantity: $(this).data('quantity'),
						price: $(this).data('price')
					}
				});
				var services = [];
				$('#tbl_services_create tbody tr').each(function (index) {
					services[index] = {
						description: $(this).data('description'),
						quantity: $(this).data('quantity'),
						price: $(this).data('price')
					}
				});
				var data = {
					_token: btn.data('token'),
					customer_id: $('#create_customer_id').val(),
					proyect: $('#create_proyect').val(),
					attention: $('#create_attention').val(),
					date: $('#create_date').val(),
					expire: $('#create_expire').val(),
					specifications: $('#create_specifications').val(),
					products: products,
					services: services,
					freight: ((isNaN($('#create_freight').val()) || $('#create_freight').val() == '') ? 0 : round_dec($('#create_freight').val(), 2)),
					discount: ((isNaN($('#create_discount').val()) || $('#create_discount').val() == '') ? 0 : $('#create_discount').val()),
					exempt: $('#create_exempt:checked').size(),
					option: btn.data('option'),
					annotations: $('#create_annotations').val()
				}
				ejecutar_ajax('/proformas/almacenar', 'html', data, btn, function(result) {
					$("#tbl_proforms tbody").prepend(result);
					$('.panel-footer small span').text($('#tbl_proforms tbody tr').length);
					$('#createProformModal').modal('hide');
				});
				return false;
			});

	// EDIT PROFORM
		$(document).off('click', '.btnEditProformModal').on('click', '.btnEditProformModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#editProformModal .modal-body').html('');
			ejecutar_ajax('/proformas/' + button.data('proform-id') + '/editar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#editProformModal .modal-body').html(result);
				$('#editProformModal .modal-title .modal-title-id').text(button.data('number'));
				$('#edit_customer_id, #edit_product_id').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#edit_date, #edit_expire').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
				$("#edit_exempt").bootstrapSwitch();
				sortTable('tbl_services_edit', true);
				sortTable('tbl_products_edit', true);
				calculateEditProformTotal();
				$('#editProformModal').modal('show');
			});
			return false;
		});

		$('#editProformModal').on('shown.bs.modal', function (event) {
			$('#edit_customer_id').trigger('chosen:activate');
		});

		function calculateEditProformTotal()
		{
			var total_proform = 0;

			$('#tbl_products_edit tbody tr').each(function (index) {
				total_proform += round_dec(round_dec($(this).data('quantity'), 2) * round_dec($(this).data('price'), 2), 2);
			});
			//$('#txt_total_products_edit').text((total_products).formatMoney());

			$('#tbl_services_edit tbody tr').each(function (index) {
				total_proform += round_dec(round_dec($(this).data('quantity'), 2) * round_dec($(this).data('price'), 2), 2);
			});
			//$('#txt_total_services_edit').text((total_services).formatMoney());

			$('#edit_sub_total').val(total_proform.formatMoney(2, ''));

			var discount_percentage = (isNaN($('#edit_discount').val()) || $('#edit_discount').val() == '' ? 0 : $('#edit_discount').val());
			var discount = round_dec(total_proform * (parseFloat(discount_percentage) / 100), 2);
			$('#edit_discount_total').val(discount.formatMoney(2, ''));
			total_proform -= discount;

			var freight = (isNaN($('#edit_freight').val()) || $('#edit_freight').val() == '' ? 0 : round_dec($('#edit_freight').val(), 2));
			total_proform += freight;

			if ($('#edit_exempt').is(':checked')) {
				$('#edit_tax').val('0.00');
			} else {
				var tax = round_dec(total_proform * parseFloat($('#edit_tax').data('tax')), 2);
				$('#edit_tax').val(tax.formatMoney(2, ''));
				total_proform += tax;
			}

			$('#edit_proform_total').text(round_dec(total_proform, 2).formatMoney(2, 'L '));
		}

		$(document).on('keyup', '#edit_discount', function(e){
			if (!isNaN($(this).val())) {
				calculateEditProformTotal();
			}
		});

		$(document).on('keyup', '#edit_freight', function(e){
			if (!isNaN($(this).val())) {
				calculateEditProformTotal();
			}
		});

		$(document).on('switchChange.bootstrapSwitch', '#edit_exempt', function(event, state) {
			calculateEditProformTotal();
		});

		$(document).on('change', '#edit_product_id', function() {
			if ($(this).val() == '') {
				$('#edit_product_price').val('');
			} else {
				$('#edit_product_price').val(round_dec($('#edit_product_id option:selected').data('price'), 2).formatMoney(2, ''));
				$('#edit_product_quantity').focus();
			}
		});

		// ADD SERVICE TO TABLE
			$(document).off('click', '#btn_add_service_edit').on('click', '#btn_add_service_edit', function(e) {
				e.stopPropagation();

				var btn = $(this);
				var description = $('#edit_service_description').val().trim();
				var quantity = (isNaN($('#edit_service_quantity').val()) || $('#edit_service_quantity').val() == '' ? 0 : round_dec($('#edit_service_quantity').val(), 2));
				var price = (isNaN($('#edit_service_price').val()) || $('#edit_service_price').val() == '' ? 0 : round_dec($('#edit_service_price').val(), 2));

				if (description != '' && price > 0 && quantity > 0) {
					if (btn.data('option') == 0) {
						$('#tbl_services_edit').find('tbody')
							.append(
								$('<tr>').attr('data-description', description).attr('data-quantity', quantity).attr('data-price', price)
									.append('<td>&nbsp;</td>')
									.append('<td>' + description + '</td>')
									.append('<td style="text-align: right;">' + quantity.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + price.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + round_dec(price * quantity, 2).formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-duplicate-service-edit"><span class="glyphicon glyphicon-duplicate" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-edit-service-edit"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-edit"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#tbl_services_edit tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
					} else {
						var row = $('#tbl_services_edit tbody tr.warning');
						row.data('description', description);
						row.data('quantity', quantity);
						row.data('price', price);
						row.children('td').eq(1).html(description);
						row.children('td').eq(2).text(quantity.formatMoney(2, ''));
						row.children('td').eq(3).text(price.formatMoney(2, ''));
						row.children('td').eq(4).text(round_dec(price * quantity, 2).formatMoney(2, ''));
						row.removeClass('warning');
						btn.html('<span class="glyphicon glyphicon-plus" aria-hidden="true"></span>');
						btn.data('option', 0);
					}
					$('#editProformModal').data('bs.modal').handleUpdate();
					calculateEditProformTotal();
					$('#edit_service_description, #edit_service_quantity, #edit_service_top, #edit_service_width, #edit_service_depth, #edit_service_location, #edit_service_price').val('');
					$('#edit_service_unit').val('').trigger('chosen:updated');
					$('#edit_service_description').focus();
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// EDIT ELEMENT
			$(document).off('click', '.btn-edit-service-edit').on('click', '.btn-edit-service-edit', function(e) {
				e.stopPropagation();
				if ($('#btn_add_service_edit').data('option') == 1) {
					alert('Ya existe un elemento en estado de edición.');
				} else {
					var row = $(this).closest('tr');
					row.addClass('warning');
					$('#btn_add_service_edit').data('option', 1);
					$('#edit_service_description').val(row.data('description'));
					$('#edit_service_quantity').val(row.data('quantity'));
					$('#edit_service_price').val(row.data('price'));
					$('#btn_add_service_edit').html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
					$('#edit_service_description').focus();
				}
				return false;
			});

		// DUPLICATE ELEMENT
			$(document).off('click', '.btn-duplicate-service-edit').on('click', '.btn-duplicate-service-edit', function(e) {
				e.stopPropagation();
				var old = $(this).closest('tr');
				var row = $(this).closest('tr').clone();
				row.removeClass('warning');
				row.data('description', old.data('description'));
				row.data('quantity', old.data('quantity'));
				row.data('price', old.data('price'));
				row.appendTo('#tbl_services_edit tbody');
				$('#tbl_services_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#editProformModal').data('bs.modal').handleUpdate();
				calculateEditProformTotal();
				return false;
			});

		// ADD PRODUCT TO TABLE
			$(document).off('click', '#btn_add_product_edit').on('click', '#btn_add_product_edit', function(e) {
				e.stopPropagation();

				var product_id = ( isNaN( $('#edit_product_id').val() ) || $('#edit_product_id').val() == '' ? 0 : parseInt( $('#edit_product_id').val() ) );
				var quantity = (isNaN($('#edit_product_quantity').val()) || $('#edit_product_quantity').val() == '' ? 0 : round_dec($('#edit_product_quantity').val(), 2));
				var price = (isNaN($('#edit_product_id option:selected').data('price')) || $('#edit_product_id option:selected').data('price') == '' ? 0 : round_dec($('#edit_product_id option:selected').data('price'), 2));

				if (product_id > 0 && quantity > 0 && price > 0) {
					var check = false;
					$('#tbl_products_edit tbody tr').each(function (index) {
						if ($(this).data('product-id') == $('#edit_product_id').val()) {
							check = true;
						}
					});
					if (check) {
						alert('El producto ya es parte de la Proforma.');
					} else {
						$('#tbl_products_edit').find('tbody')
							.append(
								$('<tr>').attr('data-product-id', product_id).attr('data-quantity', quantity).attr('data-price', price)
									.append('<td>&nbsp;</td>')
									.append('<td>' + $('#edit_product_id option:selected').text() + '</td>')
									.append('<td style="text-align: right;">' + quantity.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + price.formatMoney(2, '') + '</td>')
									.append('<td style="text-align: right;">' + round_dec(quantity * price, 2).formatMoney(2, '') + '</td>')
									.append(
										$('<td>').attr('class', 'text-right').attr('style', 'width: 140px;')
											.append($('<div>').attr('class', 'btn-group').attr('role', 'group')
												.append('<button class="btn btn-default btn-add-quantity-edit"><span class="glyphicon glyphicon-chevron-up" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-substract-quantity-edit"><span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span></button>')
												.append('<button class="btn btn-default btn-remove-element-edit"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>')
											)
									)
							);
						$('#editProformModal').data('bs.modal').handleUpdate();
						$('#tbl_products_edit tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
						calculateEditProformTotal();
						$('#edit_product_id').val(0).trigger('chosen:updated').trigger('chosen:activate');
						$('#edit_product_quantity').val('');
						$('#edit_product_price').val('');
					}
				} else {
					alert('Datos introducidos faltantes e/o inválidos, verifique los campos para continuar.');
				}
				return false;
			});

		// ADD QUANTITY TO ELEMENT
			$(document).off('click', '.btn-add-quantity-edit').on('click', '.btn-add-quantity-edit', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity++;
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateEditProformTotal();
				return false;
			});

		// SUBSTRACT QUANTITY TO ELEMENT
			$(document).off('click', '.btn-substract-quantity-edit').on('click', '.btn-substract-quantity-edit', function(e) {
				e.stopPropagation();
				var row = $(this).closest('tr');
				var quantity = round_dec(row.data('quantity'), 2);
				var price = round_dec(row.data('price'), 2);
				quantity--;
				if (quantity <= 0) {
					return false;
				}
				row.data('quantity', round_dec(quantity, 2));
				row.children('td').eq(2).text(quantity.formatMoney(2, ''));
				var total = round_dec(quantity * price, 2);
				row.children('td').eq(4).text(total.formatMoney(2, ''));
				calculateEditProformTotal();
				return false;
			});

		// REMOVE ELEMENT FROM TABLE
			$(document).off('click', '.btn-remove-element-edit').on('click', '.btn-remove-element-edit', function(e) {
				e.stopPropagation();
				$(this).closest('tr').remove();
				$('#tbl_products_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				$('#tbl_services_edit tbody tr').each(function (index) { $(this).children('td').eq(0).text(index + 1); });
				calculateEditProformTotal();
				$('#btn_add_service_edit').data('option', 0);
				return false;
			});

		// UPDATE PROFORM
			$('.btn_proform_edit').on('click', function(e) {
				e.stopPropagation();
				var btn = $(this);
				if (btn.data('option') == 1) {
					if (!confirm('¿Seguro(a) qué desea procesar la proforma?')) {
						return false;
					}
				}
				var products = [];
				$('#tbl_products_edit tbody tr').each(function (index) {
					products[index] = {
						product_id: $(this).data('product-id'),
						quantity: $(this).data('quantity'),
						price: $(this).data('price')
					}
				});
				var services = [];
				$('#tbl_services_edit tbody tr').each(function (index) {
					services[index] = {
						description: $(this).data('description'),
						quantity: $(this).data('quantity'),
						price: $(this).data('price')
					}
				});
				var data = {
					_token: btn.data('token'),
					_method: 'PUT',
					customer_id: $('#edit_customer_id').val(),
					proyect: $('#edit_proyect').val(),
					attention: $('#edit_attention').val(),
					date: $('#edit_date').val(),
					expire: $('#edit_expire').val(),
					specifications: $('#edit_specifications').val(),
					products: products,
					services: services,
					freight: ((isNaN($('#edit_freight').val()) || $('#edit_freight').val() == '') ? 0 : round_dec($('#edit_freight').val(), 2)),
					discount: ((isNaN($('#edit_discount').val()) || $('#edit_discount').val() == '') ? 0 : $('#edit_discount').val()),
					exempt: $('#edit_exempt:checked').size(),
					option: btn.data('option'),
					annotations: $('#edit_annotations').val()
				}
				ejecutar_ajax('/proformas/' + $('#edit_proform_id').val(), 'html', data, btn, function(result) {
					var row = $("#tbl_proforms tbody").find("tr[data-proform-id='" + $('#edit_proform_id').val() + "']");
					row.html(result).addClass('warning');
					$('#editProformModal').modal('hide');
					toastr.info('Datos Actualizados');
				});
				return false;
			});

	// DELETE PROFORM
		$('#deleteProformModal').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			$("#deleteProformModal .modal-body span").text(button.data('number'));
			$('#btn_proforms_delete').data('proform-id', button.data('proform-id'));
		});

		$('#btn_proforms_delete').on('click', function(e) {
			e.stopPropagation();
			var proform_id = $(this).data('proform-id');
			var datos = {
				_token: $(this).data('token'),
				_method: 'DELETE'
			}
			ejecutar_ajax('/proformas/' + proform_id, 'json', datos, $(this), function(result) {
				if (result == 1) {
					$("#tbl_proforms tbody").find("tr[data-proform-id='" + proform_id + "']").remove();
					$('.panel-footer small span').text($('#tbl_proforms tbody tr').length);
					$('#deleteProformModal').modal('hide');
					toastr.warning('Proforma Eliminada');
				}
			});
			return false;
		});

	// SHOW PROFORM
		$(document).off('click', '.btnShowProformModal').on('click', '.btnShowProformModal', function(e) {
			e.stopPropagation();
			var button = $(this);
			$('#showProformModal .modal-body').html('');
			ejecutar_ajax('/proformas/' + button.data('proform-id'), 'html', { _token: button.data('token') }, button, function(result) {
				$('#showProformModal .modal-body').html(result);
				$('#showProformModal .modal-title .modal-title-id').text(button.data('number'));
				$('#showProformModal').modal('show');
			});
			return false;
		});

	// DUPLICATE PROFORM
		$(document).off('click', '#btnDuplicateProform').on('click', '#btnDuplicateProform', function(e) {
			e.stopPropagation();
			var button = $(this);
			ejecutar_ajax('/proformas/' + button.data('proform-id') + '/duplicar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#showProformModal').modal('hide');
				$('#tbl_proforms tbody').prepend(result);
				$('.panel-footer small span').text($('#tbl_proforms tbody tr').length);
				toastr.info('Proforma Duplicada');
			});
			return false;
		});

	// E-MAIL PROFORM
		$('#emailProformModal').on('show.bs.modal', function (event) {
			$('#txt_email_to, txt_email_subject, #txt_email_message').val('');
			if (CKEDITOR.instances.txt_email_message) {
				CKEDITOR.instances.txt_email_message.destroy();
			}
			CKEDITOR.replace('txt_email_message', { language: 'es' });
			CKEDITOR.instances.txt_email_message.setData('');
			var button = $(event.relatedTarget); // Button that triggered the modal
			$('#emailProformModal .modal-title-id').text(button.data('number'));
			$('#txt_email_to').val(button.data('email'));
			$('#txt_email_subject').val('Proforma #' + button.data('number'));
			$('#btn_proforms_email').data('proform-id', button.data('proform-id'));
		});

		$('#emailProformModal').on('shown.bs.modal', function (event) {
			$('#txt_email_to').focus();
		});

		$(document).off('click', '#btn_proforms_email').on('click', '#btn_proforms_email', function(e) {
			e.stopPropagation();
			var btn = $(this);
			var data = {
				_token: btn.data('token'),
				to: $('#txt_email_to').val(),
				subject: $('#txt_email_subject').val(),
				msg: CKEDITOR.instances['txt_email_message'].getData()
			}
			ejecutar_ajax('/proformas/' + btn.data('proform-id') + '/email', 'json', data, btn, function(result) {
				if (result == 1) {
					$('#emailProformModal').modal('hide');
					toastr.info('Proforma Enviada');
				}
			});
			return false;
		});

	// REJECT PROFORM
		$(document).off('click', '#btnRejectProform').on('click', '#btnRejectProform', function(e) {
			e.stopPropagation();
			var button = $(this);
			if (confirm('¿Seguro(a) qué desea rechazar la proforma?')) {
				ejecutar_ajax('/proformas/' + button.data('proform-id') + '/rechazar', 'html', { _token: button.data('token') }, button, function(result) {
					$('#showProformModal').modal('hide');
					var row = $("#tbl_proforms tbody").find("tr[data-proform-id='" + button.data('proform-id') + "']");
					row.html(result).addClass('warning');
					toastr.warning('Proforma Rechazada');
				});
			}
			return false;
		});

	// PROCESS PROFORM
		$(document).off('click', '#btnAcceptProform').on('click', '#btnAcceptProform', function(e) {
			e.stopPropagation();
			if (confirm('¿Seguro(a) qué desea facturar la proforma?')) {
				var button = $(this);
				ejecutar_ajax('/proformas/' + button.data('proform-id') + '/procesar', 'html', { _token: button.data('token') }, button, function(result) {
					var row = $("#tbl_proforms tbody").find("tr[data-proform-id='" + button.data('proform-id') + "']");
					row.html(result).addClass('warning');
					$('#showProformModal').modal('hide');
					toastr.info('Proforma Facturada');
				});
			}
			return false;
		});

	// SEARCH PROFORMS
		$('#txt_proform_customer_search, #txt_proform_state_search, #txt_proform_month_search, #txt_proform_year_search').chosen({
			no_results_text: 'No se encontraron resultados para',
			allow_single_deselect: true,
			search_contains: true
		});

		$('.btn_proforms_search').on('click', function(e) {
			e.stopPropagation();
			var btn = $(this);

			if (btn.data('option') == 1 && $('#txt_proform_customer_search').val() == '') {
				$('#txt_proform_customer_search').trigger('chosen:activate');
				return false;
			}

			if (btn.data('option') == 2 && $('#txt_proform_state_search').val() == '') {
				$('#txt_proform_state_search').trigger('chosen:activate');
				return false;
			}

			if (btn.data('option') == 3 && ($('#txt_proform_month_search').val() == '' || $('#txt_proform_year_search').val() == '')) {
				alert('Fecha incompleta.');
				return false;
			}

			var data = {
				_method: 'POST',
				_token: btn.data('token'),
				customer_id: $('#txt_proform_customer_search').val(),
				state: $('#txt_proform_state_search').val(),
				month: $('#txt_proform_month_search').val(),
				year: $('#txt_proform_year_search').val(),
				option: btn.data('option')
			};
			ejecutar_ajax('/proformas/busqueda', 'html', data, btn, function(result) {
				$('#searchModal').modal('hide');
				$("#tbl_proforms tbody").hide().html(result).fadeIn('slow');
				$('.panel-footer small span').text($("#tbl_proforms tbody tr").length);
				var total = $("#tbl_proforms tbody tr").length;
				if (total == 0) {
					toastr.error('No se Encontraron proformas');
				} else {
					toastr.info(total + ' Proforma(s) Encontrada(s)');
				}
			});

			return false;
		});

}

if ($('#pg_offices').length > 0) {

	$(".btn_menu_config").addClass('active');

	// CREATE OFFICE
		$('#btnCreateOfficeModal').on('click', function(e){
			e.stopPropagation();
			$('#createOfficeModal .modal-body').html('');
			ejecutar_ajax('/sucursales/crear', 'html', { _token: $(this).data('token') }, $(this), function(result) {
				$('#createOfficeModal .modal-body').html(result);
				$('#createOfficeModal').modal('show');
			});
			return false;
		});

		$('#createOfficeModal').on('shown.bs.modal', function () {
			$('#create_office_name').focus();
		});

		$('.btn_office_create').on('click', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/sucursales/almacenar', 'html', $('#frm_offices_create').serialize(), btn, function(result) {
				$('#tbl_offices tbody').prepend(result);
				$('#tbl_offices tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('.panel-footer small span').text($('#tbl_offices tbody tr').length);
				$('#createOfficeModal').modal('hide');
				toastr.success('Sucursal Creada');
			});
			return false;
		});

	// EDIT OFFICE
		$(document).off('click', '.btnEditOfficeModal').on('click', '.btnEditOfficeModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#editOfficeModal .modal-body').html('');
			ejecutar_ajax('/sucursales/' + button.data('office-id') + '/editar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#editOfficeModal .modal-body').html(result);
				$('#editOfficeModal .modal-title .modal-title-name').text(button.data('name'));
				$('#editOfficeModal').modal('show');
			});
			return false;
		});

		$('#editOfficeModal').on('shown.bs.modal', function () {
			$('#edit_office_name').focus();
		});

		$('.btn_office_edit').on('click', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/sucursales/' + $("#edit_office_id").val(), 'html', $('#frm_offices_edit').serialize(), $(this), function(result) {
				var row = $("#tbl_offices tbody").find("tr[data-office-id='" + $('#edit_office_id').val() + "']");
				row.html(result).addClass('warning');
				$('#tbl_offices tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('#editOfficeModal').modal('hide');
				toastr.info('Sucursal Actualizada');
			});
			return false;
		});

	// DELETE OFFICE
		$('#deleteOfficeModal').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			$("#deleteOfficeModal .modal-body span").text(button.data('name'));
			$('#btn_offices_delete').data('office-id', button.data('office-id'));
		});

		$('#btn_offices_delete').on('click', function(e) {
			e.stopPropagation();
			var office_id = $(this).data('office-id');
			var datos = {
				_token: $(this).data('token'),
				_method: 'DELETE'
			}
			ejecutar_ajax('/sucursales/' + office_id, 'html', datos, $(this), function(result) {
				$("#tbl_offices tbody").find("tr[data-office-id='" + office_id + "']").remove();
				$('#tbl_offices tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('.panel-footer small span').text($('#tbl_offices tbody tr').length);
				$('#deleteOfficeModal').modal('hide');
				toastr.warning('Sucursal Eliminada');
			});
			return false;
		});

}

var base = window.location.origin;

// DISABLE LOADING BUTTON
	$('.btn-load').on('click', function (e) {
		var btn = $(this);
		btn.data('loading-text', '<img src="/img/' + btn.data('load-status') + '.gif"> ' + btn.text()).attr('autocomplete', 'off');
		var $btn = btn.button('loading');
	});

// TOASTR
	toastr.options = {
	    "closeButton": false,
	    "debug": false,
	    "newestOnTop": false,
	    "progressBar": false,
	    "positionClass": "toast-bottom-left",
	    "preventDuplicates": false,
	    "onclick": null,
	    "showDuration": "300",
	    "hideDuration": "1000",
	    "timeOut": "5000",
	    "extendedTimeOut": "1000",
	    "showEasing": "swing",
	    "hideEasing": "linear",
	    "showMethod": "fadeIn",
	    "hideMethod": "fadeOut"
	}

// AJAX REQUESTS HELPER FUNCTION
    $.ajaxSetup({
        cache: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name=delete-token]').attr("content")
        }
    });
	function ejecutar_ajax(direccion, tipo_datos, datos, boton, callback)
	{
		if (boton !== null) {
			if (boton.length > 0) {
				boton.button('loading');
			}
		}
		$.ajax({
			url: base + direccion,
			type: 'post',
			dataType: tipo_datos,
			data: datos,
			complete: function() {
				if (boton !== null) {
					if (boton.length > 0) {
						boton.button('reset');
					}
				}
			},
			success: callback,
			error: function(xhr, ajaxOption, thrownError) {
				try {
					var errors = JSON.parse(xhr.responseText);
					var mensaje = 'Errores:\n\n';
					$.each(errors, function(key, value) {
						mensaje += (value + '\n');
					});
					alert(mensaje);
				}
				catch (err) {
					console.log(thrownError);
					console.log(xhr.responseText);
					var myWindow = window.open('', 'MsgWindow');
					myWindow.document.write(xhr.responseText);
				}
			}
		});
	}

// CHECK IF ITS INTEGER
	function isInteger(x)
	{
		return (typeof x === 'number') && (x % 1 === 0);
	}

// CURRENCY FORMAT
	Number.prototype.formatMoney = function(places, symbol, thousand, decimal)
	{
		places = !isNaN(places = Math.abs(places)) ? places : 2;
		//symbol = symbol !== undefined ? symbol : "L ";
		thousand = thousand || ",";
		decimal = decimal || ".";
		var number = this,
			negative = number < 0 ? "-" : "",
			i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
			j = (j = i.length) > 3 ? j % 3 : 0;
		return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
	};

// REMOVE CURRENCY FORMAT
	Number.prototype.removeFormatMoney = function(value)
	{
		return parseFloat(value.replace(/[^0-9-,]/g, ''));
	}

function resetInput($form)
{
	$form.find('input:text, input:password, input:file, textarea').val('').prop('disabled', false);
}

function flash_message(type, message)
{
	if (type == 'info') {
		$('.flash').html('<div class="alert alert-success" role="alert"><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> ' + message + '</p></div>').hide().fadeIn('slow').delay(4000).slideUp(300);
	}

	if (type == 'atencion') {
		$('.flash').html('<div class="alert alert-warning" role="alert"><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> ' + message + '</p></div>').hide().fadeIn('slow').delay(4000).slideUp(300);
	}
}

// LOADING BUTTONS
	/*$('.btn-load').on('click', function (e) {
		$(this).data('loading-text', '<img src="' + base + '/img/' + $(this).data('block-option') + '.gif"> Cargando...').attr('autocomplete', 'off');
		var $btn = $(this).button('loading');
	});*/

// SEARCH BOX
	function search_box(table_id, search_input, column)
	{
		//add index column with all content.
		$("#" + table_id + " tr:has(td)").each(function(){
			if ($(this).children('td').eq(column).length > 0) {
				$(this).children('td').eq(column).remove();
			}
			var t = $(this).text().toLowerCase(); //all row text
			$("<td class='indexColumn'></td>").hide().text(t).appendTo(this);
		});//each tr

		var s = search_input.val().toLowerCase().split(" ");
		//show all rows.
		$("#" + table_id + " tr:hidden").show();
		$.each(s, function(){
			$("#" + table_id + " tr:visible .indexColumn:not(:contains('" + this + "'))").parent().hide();
		});//each
	}

// CHANGE PASSWORD
	$('#changePasswordModal').on('shown.bs.modal', function () {
		$('#old_password').focus();
	});

	$('#btn_change_password').on('click', function(e) {
		e.stopPropagation();
		ejecutar_ajax('/contrasena', 'json', $('#frm_change_password').serialize(), $(this), function(result) {
			if (result.status == 1) {
				$('#changePasswordModal').modal('hide');
				resetInput($("#frm_change_password"));
				flash_message('info', 'La contraseña fue cambiada satisfactoriamente.');
			} else {
				alert('Errores:\n\n' + result.message);
			}
		});
		return false;
	});

//$("div.alert").not(".alert-danger").delay(4000).slideUp(300);

//Helper function to keep table row from collapsing when being sorted
	var fixHelperModified = function(e, tr) {
		var $originals = tr.children();
		var $helper = tr.clone();
		$helper.children().each(function(index)
		{
		  $(this).width($originals.eq(index).width())
		});
		return $helper;
	};

	function sortTable(table, reorder)
	{
		$('#' + table + ' tbody').sortable({
			helper: fixHelperModified,
			stop: function(event,ui) {
				if (reorder) {
					$('#' + table + ' tbody tr').each(function(index) {
						$(this).children('td').eq(0).text(index + 1);
					});
				}
			}
		}).disableSelection();
	}

// ROUND NUMBER
	function round_dec(value, decimals) {
		return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
	}

// HIDE MODALS ON BROWSER BACK BUTTON
    $(document).off('show.bs.modal', 'div.modal').on('show.bs.modal', 'div.modal', function() {
        var modal = this;
        var hash = 'modal';
        window.location.hash = hash;
        window.onhashchange = function() {
            if (!location.hash){
                $(modal).modal('hide');
            }
        }
    });

    $(document).off('hide.bs.modal', 'div.modal').on('hide.bs.modal', 'div.modal', function() {
        var hash = 'modal';
        history.pushState('', document.title, window.location.pathname);
    });

// LIMPIAR OBJETO
    function limpiarObjeto(objeto, exclusiones)
    {
        for (var propiedad in objeto) {
            if (typeof exclusiones == 'undefined') {
                objeto[propiedad] = '';
            } else {
                var resultado = false;
                exclusiones.forEach(function (exclusion) {
                    if (propiedad == exclusion) {
                        resultado = true;
                    }
                });
                if (!resultado) {
                    objeto[propiedad] = '';
                }
            }
        }
    }

// RELLENAR OBJETO
    function rellenarObjeto(objeto_a_rellenar, objeto_de_donde_rellenar, exclusiones)
    {
        for (var propiedad in objeto_a_rellenar) {
            if (typeof exclusiones == 'undefined') {
                objeto_a_rellenar[propiedad] = objeto_de_donde_rellenar[propiedad];
            } else {
                var resultado = false;
                exclusiones.forEach(function(exclusion) {
                    if (propiedad == exclusion) {
                        resultado = true;
                    }
                });
                if (!resultado) {
                    objeto_a_rellenar[propiedad] = objeto_de_donde_rellenar[propiedad];
                }
            }
        }
    }

// REMOVER ELEMENTO DE ARREGLO
    function removerObjeto(arreglo, elemento) {
        if (!arreglo.length) return;
        var indice = arreglo.indexOf(elemento);
        if (indice > -1) {
            arreglo.splice(indice, 1);
        }
    }

Vue.component('v-select', VueSelect.VueSelect)

if ($('#pg_departments').length > 0) {

	$(".btn_menu_departments").addClass('active');
	$(".btn_menu_departments").closest('.dropdown').addClass('active');

	// CREATE DEPARTMENT
		$('#btnCreateDepartmentModal').on('click', function(e){
			e.stopPropagation();
			$('#createDepartmentModal .modal-body').html('');
			ejecutar_ajax('/departamentos/crear', 'html', { _token: $(this).data('token') }, $(this), function(result) {
				$('#createDepartmentModal .modal-body').html(result);
				$('#createDepartmentModal').modal('show');
			});
			return false;
		});

		$('#createDepartmentModal').on('shown.bs.modal', function () {
			$('#create_name').focus();
		});

		$('.btn_department_create').on('click', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/departamentos/almacenar', 'html', $('#frm_departments_create').serialize(), btn, function(result) {
				$('#tbl_departments tbody').prepend(result);
				$('#tbl_departments tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('.panel-footer small span').text($('#tbl_departments tbody tr').length);
				$('#createDepartmentModal').modal('hide');
			});
			return false;
		});

	// EDIT DEPARTMENT
		$(document).off('click', '.btnEditDepartmentModal').on('click', '.btnEditDepartmentModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#editDepartmentModal .modal-body').html('');
			ejecutar_ajax('/departamentos/' + button.data('department-id') + '/editar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#editDepartmentModal .modal-body').html(result);
				$('#editDepartmentModal .modal-title .modal-title-name').text(button.data('name'));
				$('#editDepartmentModal').modal('show');
			});
			return false;
		});

		$('#editDepartmentModal').on('shown.bs.modal', function () {
			$('#edit_name').focus();
		});

		$('.btn_department_edit').on('click', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/departamentos/' + $("#edit_department_id").val(), 'html', $('#frm_departments_edit').serialize(), $(this), function(result) {
				var row = $("#tbl_departments tbody").find("tr[data-department-id='" + $('#edit_department_id').val() + "']");
				row.html(result).addClass('warning');
				$('#tbl_departments tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('#editDepartmentModal').modal('hide');
			});
			return false;
		});

	// DELETE DEPARTMENT
		$('#deleteDepartmentModal').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			$("#deleteDepartmentModal .modal-body span").text(button.data('name'));
			$('#btn_departments_delete').data('department-id', button.data('department-id'));
		});

		$('#btn_departments_delete').on('click', function(e) {
			e.stopPropagation();
			var department_id = $(this).data('department-id');
			var datos = {
				_token: $(this).data('token'),
				_method: 'DELETE'
			}
			ejecutar_ajax('/departamentos/' + department_id, 'json', datos, $(this), function(result) {
				if (result == 1) {
					$("#tbl_departments tbody").find("tr[data-department-id='" + department_id + "']").remove();
					$('#tbl_departments tbody tr').each(function (index) {
						$(this).children('td').eq(0).text(index + 1);
					});
					$('.panel-footer small span').text($('#tbl_departments tbody tr').length);
					$('#deleteDepartmentModal').modal('hide');
					//flash_message('atencion', 'La cotización fue eliminada satisfactoriamente.');
				}
			});
			return false;
		});

	// SEARCH DEPARTMENTS
		$("#txt_departments_search").keyup(function(e){
			if (e.keyCode == 27) {
				$(this).val('');
			}
			search_box('tbl_departments', $(this), 3);
		});

}

if ($('#app-cxconsolidado').length > 0) {

	$(".btn_menu_cxconsolidado").addClass('active');
	$(".btn_menu_cxconsolidado").closest('.dropdown').addClass('active');

	new Vue({
		el: '#app-cxconsolidado',

		data: {
			busqueda: {
				cliente: '',
				fecha: '',
				estado: false,
				_token: '',
			},
			clientes: [],
			reportes: [],
		},

		created: function () {
			this.busqueda._token = token;
			this.clientes = clientes;
		},

		methods: {
			obtenerDatos: function () {
				var self = this;
				if (self.busqueda.cliente != '') {
					ejecutar_ajax('/cuentasxconsolidado/busqueda', 'json', self.busqueda, $('#btnObtenerDatos'), function (cliente) {
						console.log(cliente);
						//self.reportes.push(cliente);
					});
				} else {
					self.busqueda.estado = true;
					while (self.busqueda.estado) {
						// TODOS LOS CLIENTES;
					}
				}
			},
		}
	});

}

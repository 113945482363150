if ($('#pg_suppliers').length > 0) {

	// SHOW CAIS
		$(document).off('click', '.btnSuppliersCaisModal').on('click', '.btnSuppliersCaisModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#suppliersCaisModal .modal-body').html('');
			ejecutar_ajax('/cai/' + button.data('supplier-id'), 'html', { _token: button.data('token') }, button, function(result) {
				$('#suppliersCaisModal .modal-body').html(result);
				$('#suppliersCaisModal').modal('show');
			});
			return false;
		});

		$('#suppliersCaisModal').on('shown.bs.modal', function () {
			$('#supplier_cai_name').focus();
		});

	// ADD CAI
		$(document).off('click', '#btnAddSupplierCai').on('click', '#btnAddSupplierCai', function(e) {
			e.stopPropagation();
			var btn = $(this);
			var data = {
				_token: btn.data('token'),
				_method: 'POST',
				cai: $('#supplier_cai_name').val()
			};
			ejecutar_ajax('/cai/' + btn.data('supplier-id') + '/almacenar', 'html', data, btn, function(result) {
				$("#tbl_suppliers_cais tbody").prepend(result);
				$('#tbl_suppliers_cais tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('#supplier_cai_name').val('').focus();
			});
			return false;
		});

	// REMOVE CAIS
		$(document).off('click', '.btnDeleteSupplierCai').on('click', '.btnDeleteSupplierCai', function(e) {
			e.stopPropagation();
			var btn = $(this);
			if (confirm('¿Seguro(a) qué desea remover el CAI seleccionado?')) {
				var datos = {
					_token: btn.data('token'),
					_method: 'DELETE'
				}
				ejecutar_ajax('/cai/' + btn.data('cai-id'), 'json', datos, btn, function(result) {
					if (result == 1) {
						$("#tbl_suppliers_cais tbody").find("tr[data-cai-id='" + btn.data('cai-id') + "']").remove();
						$('#tbl_suppliers_cais tbody tr').each(function (index) {
							$(this).children('td').eq(0).text(index + 1);
						});
					}
				});
			}
			return false;
		});

}

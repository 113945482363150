if ($('#pg_products').length > 0) {

	$(".btn_menu_products").addClass('active');
	$(".btn_menu_products").closest('.dropdown').addClass('active');

	// CREATE PRODUCT
		$('#btnCreateProductModal').on('click', function(e){
			e.stopPropagation();
			$('#createProductModal .modal-body').html('');
			ejecutar_ajax('/productos/crear', 'html', { _token: $(this).data('token') }, $(this), function(result) {
				$('#createProductModal .modal-body').html(result);
				$('#create_department_id, #create_supplier_id, #create_measure_id, #create_type').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#createProductModal').modal('show');
			});
			return false;
		});

		$('#createProductModal').on('shown.bs.modal', function () {
			$('#create_code').focus();
		});

		$('.btn_product_create').on('click', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/productos/almacenar', 'html', $('#frm_products_create').serialize(), btn, function(result) {
				$('#tbl_products tbody').prepend(result);
				$('#tbl_products tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('.panel-footer small span').text($('#tbl_products tbody tr').length);
				$('#createProductModal').modal('hide');
				toastr.success('Producto Creado');
			});
			return false;
		});

	// EDIT PRODUCT
		$(document).off('click', '.btnEditProductModal').on('click', '.btnEditProductModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#editProductModal .modal-body').html('');
			ejecutar_ajax('/productos/' + button.data('product-id') + '/editar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#editProductModal .modal-body').html(result);
				$('#edit_department_id, #edit_supplier_id, #edit_measure_id, #edit_state, #edit_type').chosen({
					no_results_text: 'No se encontraron resultados para',
					allow_single_deselect: true,
					search_contains: true
				});
				$('#editProductModal .modal-title .modal-title-name').text(button.data('name'));
				$('#editProductModal').modal('show');
			});
			return false;
		});

		$('#editProductModal').on('shown.bs.modal', function () {
			$('#edit_code').focus();
		});

		$('.btn_product_edit').on('click', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/productos/' + $("#edit_product_id").val(), 'html', $('#frm_products_edit').serialize(), $(this), function(result) {
				var row = $("#tbl_products tbody").find("tr[data-product-id='" + $('#edit_product_id').val() + "']");
				row.html(result).addClass('warning');
				$('#tbl_products tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('#editProductModal').modal('hide');
				toastr.info('Producto Actualizado');
			});
			return false;
		});

	// DELETE PRODUCT
		$('#deleteProductModal').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			$("#deleteProductModal .modal-body span").text(button.data('name'));
			$('#btn_products_delete').data('product-id', button.data('product-id'));
		});

		$('#btn_products_delete').on('click', function(e) {
			e.stopPropagation();
			var product_id = $(this).data('product-id');
			var datos = {
				_token: $(this).data('token'),
				_method: 'DELETE'
			}
			ejecutar_ajax('/productos/' + product_id, 'json', datos, $(this), function(result) {
				if (result == 1) {
					$("#tbl_products tbody").find("tr[data-product-id='" + product_id + "']").remove();
					$('#tbl_products tbody tr').each(function (index) {
						$(this).children('td').eq(0).text(index + 1);
					});
					$('.panel-footer small span').text($('#tbl_products tbody tr').length);
					$('#deleteProductModal').modal('hide');
					toastr.warning('Producto Eliminado');
				}
			});
			return false;
		});

	// SEARCH PRODUCTS
		$('#btnSearchProducts').on('click', function(e) {
			e.stopPropagation();
			if ($('#txt_products_search').val() !== '') {
				//search_box('tbl_products', $("#txt_products_search"), 7);
				realizar_busqueda();
			} else {
				$('#txt_products_search').focus();
			}
			return false;
		});

		$("#txt_products_search").keyup(function(e){
			if (e.keyCode == 27) {
				$(this).val('');
				//search_box('tbl_products', $(this), 7);
			}
			if (e.keyCode == 13) {
				realizar_busqueda();
			}
		});

		function realizar_busqueda() {
			var datos = {
				texto: $('#txt_products_search').val(),
				_token: $('meta[name=delete-token]').attr("content")
			};
			$('#txt_products_search').attr('disabled', true);
			ejecutar_ajax('/productos/busqueda', 'html', datos, null, function(result) {
				$('#tbl_products tbody').html(result);
				$('#txt_products_search').attr('disabled', false);
			});
		}

	// SUPPLIERS
		$(document).off('click', '.btnEditProductSuppliers').on('click', '.btnEditProductSuppliers', function(e){
			e.stopPropagation();
			var button = $(this);
			ejecutar_ajax('/productos/' + button.data('product-id') + '/proveedores', 'json', { _token: button.data('token') }, button, function(product) {
				vmSuppliers.product.id = product.id;
				vmSuppliers.add.product_id = product.id;
				vmSuppliers.product.code = product.code;
				vmSuppliers.product.name = product.name;
				vmSuppliers.products_suppliers = product.products_suppliers;
				$('#suppliersProductModal').modal('show');
			});
			return false;
		});

	var vmSuppliers = new Vue({
		el: '#suppliersProductModal',

		data: {
			product: {
				id: '', code: '', name: '',
			},
			products_suppliers: [],
			suppliers: [],
			add: {
				product_id: '', supplier_id: '', cost: '',
			}
		},

		created: function () {
			this.suppliers = suppliers;
		},

		methods: {
			addSupplier: function () {
				var self = this;
				ejecutar_ajax('/productos/proveedores/agregar', 'json', self.add, $('#btnAddSupplier'), function(product_supplier) {
					self.products_suppliers.push(product_supplier);
					self.add.supplier_id = '';
					self.add.cost = '';
					$('#suppliersProductModal').modal('handleUpdate')
					$('#txtAddSupplierID').focus();
				});
			},

			removeSupplier: function (event, product_supplier) {
				var self = this;
				if (confirm('Seguro(a) qué desea remover el proveedor seleccionado?')) {
					ejecutar_ajax('/productos/proveedores/remover', 'html', product_supplier, $(event.currentTarget), function() {
						removerObjeto(self.products_suppliers, product_supplier);
						$('#suppliersProductModal').modal('handleUpdate')
					});
				}
			},
		}
	});

}

if ($('#app-kardex').length > 0) {

	$(".btn_menu_kardex").addClass('active');
	$(".btn_menu_kardex").closest('.dropdown').addClass('active');

	var vmKardex = new Vue({
		el: '#app-kardex',

		data: {
			seleccion: {
				ano: '', mes: '', product: '', type: '', buscando: false, select2: '', tipo: '',
			},
			kardex: [],
			products: [],
			selected_product: '',
		},

		created: function () {
			this.seleccion.mes = mes;
			this.seleccion.ano = ano;
			this.kardex = kardex;
			this.products = products;
		},

		computed: {
			kardexFiltered: function () {
				var self = this;
				return self.kardex.filter(function (k) {
					return (self.seleccion.product == '' && self.seleccion.type == '' && self.seleccion.tipo == '') ||
						(self.seleccion.product == '' && self.seleccion.type == k.type && self.seleccion.tipo == '') ||
						(self.seleccion.product == k.product_id && self.seleccion.type == '' && self.seleccion.tipo == '') ||
						(self.seleccion.product == '' && self.seleccion.type == '' && self.seleccion.tipo == k.product.type) ||
						(self.seleccion.product == k.product_id && self.seleccion.type == k.type && self.seleccion.tipo == k.product.type);
				});
			},
		},

		methods: {
			buscarPorMes: function () {
				var self = this;
				if (self.seleccion.ano != '' && self.seleccion.mes != '') {
					self.seleccion.buscando = true;
					ejecutar_ajax('/kardex/busqueda', 'json', self.seleccion, null, function(kardex) {
						self.kardex = kardex;
						self.seleccion.buscando = false;
						self.seleccion.select2 = null;
						self.seleccion.type = '';
					});
				}
			},

			totalKardex: function () {
				var total = 0;
				var self = this;
				self.kardexFiltered.forEach(function (k) {
					total += (parseFloat(k.price) * parseFloat(k.quantity));
				});
				return total;
			},

			totalQuantity: function () {
				var self = this;
				var total = 0;
				self.kardexFiltered.forEach(function (k) {
					total += (parseFloat(k.quantity));
				});
				return total;
			},

			productSelected: function () {
				var sele = '';
				sele = this.seleccion.select2 ? this.seleccion.select2.id : '';
				this.seleccion.product = sele;
				this.selected_product = this.seleccion.select2 ? this.seleccion.select2.name : '';
			},
		},
	});

}

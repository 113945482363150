if ($('#pg_customers').length > 0) {

	$(".btn_menu_customers").addClass('active');
	$(".btn_menu_customers").closest('.dropdown').addClass('active');

	// CREATE CUSTOMER
		$('#btnCreateCustomerModal').on('click', function(e){
			e.stopPropagation();
			$('#createCustomerModal .modal-body').html('');
			ejecutar_ajax('/clientes/crear', 'html', { _token: $(this).data('token') }, $(this), function(result) {
				$('#createCustomerModal .modal-body').html(result);
				$('#createCustomerModal').modal('show');
			});
			return false;
		});

		$('#createCustomerModal').on('shown.bs.modal', function () {
			$('#create_rtn').focus();
		});

		$('.btn_customer_create').on('click', function(e) {
			e.stopPropagation();
			var btn = $(this);
			ejecutar_ajax('/clientes/almacenar', 'html', $('#frm_customers_create').serialize(), btn, function(result) {
				$('#tbl_customers tbody').prepend(result);
				$('#tbl_customers tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('.panel-footer small span').text($('#tbl_customers tbody tr').length);
				$('#createCustomerModal').modal('hide');
			});
			return false;
		});

	// EDIT CUSTOMER
		$(document).off('click', '.btnEditCustomerModal').on('click', '.btnEditCustomerModal', function(e){
			e.stopPropagation();
			var button = $(this);
			$('#editCustomerModal .modal-body').html('');
			ejecutar_ajax('/clientes/' + button.data('customer-id') + '/editar', 'html', { _token: button.data('token') }, button, function(result) {
				$('#editCustomerModal .modal-body').html(result);
				$('#editCustomerModal .modal-title .modal-title-name').text(button.data('name'));
				$('#editCustomerModal').modal('show');
			});
			return false;
		});

		$('#editCustomerModal').on('shown.bs.modal', function () {
			$('#edit_rtn').focus();
		});

		$('.btn_customer_edit').on('click', function(e) {
			e.stopPropagation();
			ejecutar_ajax('/clientes/' + $("#edit_customer_id").val(), 'html', $('#frm_customers_edit').serialize(), $(this), function(result) {
				var row = $("#tbl_customers tbody").find("tr[data-customer-id='" + $('#edit_customer_id').val() + "']");
				row.html(result).addClass('warning');
				$('#tbl_customers tbody tr').each(function (index) {
					$(this).children('td').eq(0).text(index + 1);
				});
				$('#editCustomerModal').modal('hide');
			});
			return false;
		});

	// DELETE CUSTOMER
		$('#deleteCustomerModal').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			$("#deleteCustomerModal .modal-body span").text(button.data('name'));
			$('#btn_customers_delete').data('customer-id', button.data('customer-id'));
		});

		$('#btn_customers_delete').on('click', function(e) {
			e.stopPropagation();
			var customer_id = $(this).data('customer-id');
			var datos = {
				_token: $(this).data('token'),
				_method: 'DELETE'
			}
			ejecutar_ajax('/clientes/' + customer_id, 'json', datos, $(this), function(result) {
				if (result == 1) {
					$("#tbl_customers tbody").find("tr[data-customer-id='" + customer_id + "']").remove();
					$('.panel-footer small span').text($('#tbl_customers tbody tr').length);
					$('#deleteCustomerModal').modal('hide');
					//flash_message('atencion', 'La cotización fue eliminada satisfactoriamente.');
				}
			});
			return false;
		});

	// SEARCH CUSTOMERS
		/*$("#txt_customers_search").keyup(function(e){
			if (e.keyCode == 27) {
				$(this).val('');
			}
			search_box('tbl_customers', $(this), 5);
		});*/

}

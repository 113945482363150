if ($('#pg_config').length > 0) {

	$('#btnEditCompanyModal').on('click', function(e) {
		e.stopPropagation();
		var btn = $(this);
		$('#editCompanyModal .modal-body').html('');
		ejecutar_ajax('/configuracion/empresa/editar', 'html', { _token: btn.data('token') }, btn, function(result) {
			$('#editCompanyModal .modal-body').html(result);
			$('#editCompanyModal').modal('show');
		});
		return false;
	});

	$('#editCompanyModal').on('shown.bs.modal', function (event) {
		$('#company_name').focus();
	});

	$('#btn_company_edit').on('click', function(e) {
		e.stopPropagation();
		ejecutar_ajax('/configuracion/empresa', 'html', $('#frm_company').serialize(), $(this), function(result) {
			$('#editCompanyModal').modal('hide');
			$('#company_info').html(result);
			toastr.info('Datos Actualizados');
		});
		return false;
	});


}
